import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC, ReactNode } from 'react';

type TanstackQueryProviderProps = {
    children: ReactNode;
};

const queryClient = new QueryClient();

export const TanstackQueryProvider: FC<TanstackQueryProviderProps> = ({
    children,
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};
