import React, { useState } from 'react';
import styled from 'styled-components';
import { PiLayoutDuotone } from 'react-icons/pi';
import { TbDeviceComputerCamera } from 'react-icons/tb';
import { media } from '../../../styles/mediaQueries';

interface MapTypeListProps {
    isNormalVisible: boolean;
    toggleNormal: () => void;
    isHybridVisible: boolean;
    toggleHybrid: () => void;
    isTerrainVisible: boolean;
    toggleTerrain: () => void;
    isCadastralVisible: boolean;
    toggleCadastralLayer: () => void;
    isStreetVisible: boolean;
    toggleStreetLayer: () => void;
}

export const MapTypeList: React.FC<MapTypeListProps> = ({
    isNormalVisible,
    toggleNormal,
    isHybridVisible,
    toggleHybrid,
    isTerrainVisible,
    toggleTerrain,
    isCadastralVisible,
    toggleCadastralLayer,
    isStreetVisible,
    toggleStreetLayer,
}) => {
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);

    const toggleModal = () => {
        setIsMapModalOpen(!isMapModalOpen);
    };

    return (
        <StMapTypeList>
            <StMapTypeBox onClick={toggleModal} $isSelected={isMapModalOpen}>
                지도
            </StMapTypeBox>
            <section>
                <StMapToggle>
                    <StNormal
                        $isSelected={isNormalVisible}
                        onClick={toggleNormal}
                    >
                        일반
                    </StNormal>
                    <StSatellite
                        $isSelected={isHybridVisible}
                        onClick={toggleHybrid}
                    >
                        위성
                    </StSatellite>
                    <StTopography
                        $isSelected={isTerrainVisible}
                        onClick={toggleTerrain}
                    >
                        지형
                    </StTopography>
                </StMapToggle>
                <StLayer>
                    <StCadastral
                        $isSelected={isCadastralVisible}
                        onClick={() => toggleCadastralLayer()}
                    >
                        <PiLayoutDuotone />
                        <p>지적도</p>
                    </StCadastral>
                    <StRoad
                        $isSelected={isStreetVisible}
                        onClick={() => toggleStreetLayer()}
                    >
                        <TbDeviceComputerCamera />
                        <p>로드뷰</p>
                    </StRoad>
                </StLayer>
            </section>
            {isMapModalOpen && (
                <StMapModal>
                    <article>
                        <StMapToggle>
                            <StNormal
                                $isSelected={isNormalVisible}
                                onClick={toggleNormal}
                            >
                                일반
                            </StNormal>
                            <StSatellite
                                $isSelected={isHybridVisible}
                                onClick={toggleHybrid}
                            >
                                위성
                            </StSatellite>
                            <StTopography
                                $isSelected={isTerrainVisible}
                                onClick={toggleTerrain}
                            >
                                지형
                            </StTopography>
                        </StMapToggle>
                        <StLayer>
                            <StCadastral
                                $isSelected={isCadastralVisible}
                                onClick={() => toggleCadastralLayer()}
                            >
                                <PiLayoutDuotone />
                                <p>지적도</p>
                            </StCadastral>
                            <StRoad
                                $isSelected={isStreetVisible}
                                onClick={() => toggleStreetLayer()}
                            >
                                <TbDeviceComputerCamera />
                                <p>로드뷰</p>
                            </StRoad>
                        </StLayer>
                    </article>
                </StMapModal>
            )}
        </StMapTypeList>
    );
};

const StMapTypeList = styled.div`
    display: flex;
    gap: 20px;
    position: absolute;
    top: 0px;
    left: 300px;
    z-index: 95;

    ${media.tablet`
    left: 280px;
    `}

    button {
        width: 40px;
        cursor: pointer;
        border: none;

        &:hover {
            opacity: 0.7;
        }
    }

    section {
        display: flex;
        gap: 20px;

        ${media.tablet`
        display: none;
    
    `}
    }

    article {
        display: flex;
        flex-direction: column;
        gap: 5px;

        ${media.desktop`
            display: none;
        `}
    }
`;

const StMapTypeBox = styled.button<{ $isSelected: boolean }>`
    white-space: nowrap;
    background-color: ${(props) => (props.$isSelected ? '#8E00EE' : 'white')};
    color: ${(props) => (props.$isSelected ? 'white' : 'black')};
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #919191;
    display: none;

    ${media.tablet`
        display: block;    
    `}
`;
const StMapToggle = styled.div`
    background-color: white;
    padding: 5px;
    box-shadow: 1px 1px 1px #919191;
    border-radius: 2px;

    ${media.tablet`
        display: flex;
        flex-direction: column;    
    `}
`;

const StNormal = styled.button<{ $isSelected: boolean }>`
    white-space: nowrap;
    border-radius: 2px;
    height: 30px;
    background-color: ${(props) => (props.$isSelected ? '#8E00EE' : 'white')};
    color: ${(props) => (props.$isSelected ? 'white' : 'black')};
`;

const StSatellite = styled.button<{ $isSelected: boolean }>`
    white-space: nowrap;
    border-radius: 2px;
    height: 30px;
    background-color: ${(props) => (props.$isSelected ? '#8E00EE' : 'white')};
    color: ${(props) => (props.$isSelected ? 'white' : 'black')};
`;

const StTopography = styled.button<{ $isSelected: boolean }>`
    white-space: nowrap;
    border-radius: 2px;
    height: 30px;
    background-color: ${(props) => (props.$isSelected ? '#8E00EE' : 'white')};
    color: ${(props) => (props.$isSelected ? 'white' : 'black')};
`;

const StLayer = styled.div`
    display: flex;
    gap: 3px;

    button {
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 2px;
        box-shadow: 1px 1px 1px #919191;
    }

    p {
        font-size: 10px;
    }

    ${media.tablet`
        display: flex;
        flex-direction: column;    
    `}
`;

const StCadastral = styled.button<{ $isSelected: boolean }>`
    white-space: nowrap;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    background-color: ${(props) => (props.$isSelected ? '#8E00EE' : 'white')};
    color: ${(props) => (props.$isSelected ? 'white' : 'black')};
`;

const StRoad = styled.button<{ $isSelected: boolean }>`
    white-space: nowrap;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    background-color: ${(props) => (props.$isSelected ? '#8E00EE' : 'white')};
    color: ${(props) => (props.$isSelected ? 'white' : 'black')};
`;

const StMapModal = styled.div`
    position: absolute;
    top: 50px;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    z-index: 95;
`;
