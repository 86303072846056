import React from 'react';
import styled, { keyframes } from 'styled-components';
import { IoNotificationsOutline } from 'react-icons/io5';
import { StModalProps } from './DrawContainer';
import { media } from '../../../styles/mediaQueries';
import { useQuery } from '@tanstack/react-query';
import { getItemsInMyPolygons, getSummary } from '../../../api/notificationApi';

export const NotificationContainer = ({
    setIsNotificationModalOpen,
    isNotificationModalOpen,
    setIsDrawingModalOpen,
    setIsFilterModalOpen,
    setIsPolygonListModalOpen,
    setIsBookmarkModalOpen,
    map,
}) => {
    const otherModalClose = () => {
        setIsDrawingModalOpen(false);
        setIsFilterModalOpen(false);
        setIsPolygonListModalOpen(false);
        setIsBookmarkModalOpen(false);
    };

    const { data: summary, isLoading: isLoadingSummary } = useQuery({
        queryKey: ['getSummary'],
        queryFn: getSummary,
    });

    const { data: itemsInMyPolygons, isLoading: isLoadingItemsInMyPolygons } =
        useQuery({
            queryKey: ['getItemsInMyPolygons'],
            queryFn: getItemsInMyPolygons,
        });

    const isLoading = isLoadingSummary || isLoadingItemsInMyPolygons;

    const handleItemClick = (coordinates) => {
        if (map && coordinates.length !== 0) {
            const [lng, lat] = coordinates;
            map.setCenter(new naver.maps.LatLng(lat, lng));
            map.setZoom(19);
        }
    };

    return (
        <StNotificationContainer>
            <StNotificationIcon
                onClick={() => {
                    setIsNotificationModalOpen(!isNotificationModalOpen);
                    otherModalClose();
                }}
                $isOpen={isNotificationModalOpen}
            >
                <IoNotificationsOutline />
            </StNotificationIcon>
            {isNotificationModalOpen && (
                <StNotificationModal>
                    {isLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <StItemsInMyPolygonsContainer>
                                <h2>영역별 신규 매물</h2>
                                {itemsInMyPolygons?.map((item) => (
                                    <StItemContainer key={item.geometry._id}>
                                        <h3>{item.geometry.properties.name}</h3>
                                        {item.properties.length > 0 ? (
                                            item.properties.map((property) => (
                                                <StItem
                                                    key={property._id}
                                                    $source={property.source}
                                                    onClick={() =>
                                                        handleItemClick(
                                                            property.geoJson
                                                                .coordinates
                                                        )
                                                    }
                                                >
                                                    <div>
                                                        {property.category}
                                                    </div>
                                                    <div>
                                                        {
                                                            property.geoJson
                                                                .properties
                                                                .address
                                                        }
                                                    </div>
                                                </StItem>
                                            ))
                                        ) : (
                                            <StEmpty>
                                                신규 매물이 없습니다.
                                            </StEmpty> // 신규 매물이 없을 때 표시될 메시지
                                        )}
                                    </StItemContainer>
                                ))}
                            </StItemsInMyPolygonsContainer>
                            <StSummaryContainer>
                                <h2>매물 현황</h2>
                                <StAll>
                                    <p>
                                        총 : {summary?.total.toLocaleString()}
                                    </p>
                                    <p>
                                        신규 : {summary?.new.toLocaleString()}
                                    </p>
                                    <p>
                                        유효 :{' '}
                                        {summary?.ongoing.toLocaleString()}
                                    </p>
                                    <p>
                                        만료 :{' '}
                                        {summary?.archived.toLocaleString()}
                                    </p>
                                </StAll>
                                <StRent>
                                    <p>
                                        임대(총) :{' '}
                                        {summary?.totalRent.toLocaleString()}
                                    </p>
                                    <p>
                                        임대(신규) :{' '}
                                        {summary?.newRent.toLocaleString()}
                                    </p>
                                    <p>
                                        임대(유효) :{' '}
                                        {summary?.ongoingRent.toLocaleString()}
                                    </p>
                                    <p>
                                        임대(만료) :{' '}
                                        {summary?.archivedRent.toLocaleString()}
                                    </p>
                                </StRent>
                                <StSale>
                                    <p>
                                        매매(총) :{' '}
                                        {summary?.totalSale.toLocaleString()}
                                    </p>
                                    <p>
                                        매매(신규) :{' '}
                                        {summary?.newSale.toLocaleString()}
                                    </p>
                                    <p>
                                        매매(유효) :{' '}
                                        {summary?.ongoingSale.toLocaleString()}
                                    </p>
                                    <p>
                                        매매(만료) :{' '}
                                        {summary?.archivedSale.toLocaleString()}
                                    </p>
                                </StSale>
                            </StSummaryContainer>
                        </>
                    )}
                </StNotificationModal>
            )}
        </StNotificationContainer>
    );
};

const StNotificationContainer = styled.div`
    position: relative;
`;

const StNotificationIcon = styled.button<StModalProps>`
    background-color: ${(props) => (props.$isOpen ? '#8e00ee' : 'white')};
    color: ${(props) => (props.$isOpen ? 'white' : 'black')};
    font-size: 15px;
`;

const StItemsInMyPolygonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const StItem = styled.div<{ $source }>`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 18px 10px 10px;
    background-color: ${(props) => {
        if (props.$source === 'AUCTION') return '#FF427B';
        if (props.$source === 'ONBID') return '#278afb';
        if (props.$source === 'NAVER') return '#16AE53';
        if (props.$source.includes('RENT') !== -1) return '#ffc800';
        return 'transparent';
    }};
    font-size: 12px;
    color: white;
    border-radius: 4px;
    max-width: 180px;
    margin-left: 10px;

    &:hover {
        opacity: 0.8;
    }
`;

const StEmpty = styled.div`
    color: grey;
    margin-left: 10px;
    font-size: 12px;
`;

const StNotificationModal = styled.div`
    position: absolute;
    top: -140px;
    right: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 15px 15px;
    z-index: 90;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #919191;
    width: 250px;
    max-height: 200px;
    overflow: auto;

    h2 {
        display: block;
        font-weight: bold;
        font-size: 18px;
    }

    h3 {
        display: block;
        font-weight: 600;
        font-size: 16px;
        margin-left: 5px;
        margin-bottom: 5px;
    }

    ${media.tablet`
        right: 50px;
        top: -60px;
        max-height: 100px;
    `}
`;

const StSummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
        margin-left: 5px;
    }
`;

const StAll = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 14px;
    font-weight: 600;
`;

const StRent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 14px;
    font-weight: 400;
`;

const StSale = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 14px;
    font-weight: 400;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
    border: 10px solid #f3f3f3;
    border-top: 10px solid #8e00ee;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: ${spin} 2s linear infinite;

    margin: 10px 90px;
`;
