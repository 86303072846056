import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getDongPropertyList } from '../../../api/propertyApi';
import { media } from '../../../styles/mediaQueries';
import { MdOutlineCancel } from 'react-icons/md';

export const DongItemListModal = ({
    selectedSido,
    selectedSigungu,
    selectedDong,
    filterObject,
    setIsDongItemListModalOpen,
    setSelectedProperty,
    setIsDetailModalOpen,
    map,
}) => {
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastItemId, setLastItemId] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef(null);
    const lastItemRef = useRef(null);

    console.log('items', items);

    // 아이템 불러오기 함수
    const loadItems = async () => {
        if (isLoading || !hasMore) return; // Check if loading or no more items
        setIsLoading(true);

        try {
            const newItems = await getDongPropertyList(
                selectedSido,
                selectedSigungu,
                selectedDong,
                lastItemId,
                filterObject
            );
            setItems((prev) => [...prev, ...newItems]);
            if (newItems.length > 0) {
                setLastItemId(newItems[newItems.length - 1]._id);
                setHasMore(true); // Assume there might be more items
            } else {
                setHasMore(false); // No new items, end of data
            }
        } catch (error) {
            console.error('Error loading items:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // 마지막 아이템에 대한 옵저버 설정
    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && !isLoading && hasMore) {
                loadItems();
            }
        });
        if (lastItemRef.current) {
            observer.current.observe(lastItemRef.current);
        }
    }, [lastItemRef, isLoading, hasMore]); // Include hasMore in dependencies

    const onClickItem = (item, coordinates) => {
        setIsDetailModalOpen(true);
        setIsDongItemListModalOpen(false);
        setSelectedProperty(item);

        if (map && coordinates.length !== 0) {
            const [lng, lat] = coordinates;
            map.setCenter(new naver.maps.LatLng(lat, lng));
            map.setZoom(19);
        }
    };

    return (
        <StDongItemListModal>
            <StCancelButton onClick={() => setIsDongItemListModalOpen(false)}>
                <MdOutlineCancel />
            </StCancelButton>
            <StContent>
                {items?.map((item) => (
                    <StItem
                        key={item._id}
                        $source={item.source}
                        onClick={() =>
                            onClickItem(item, item.geoJson.coordinates)
                        }
                    >
                        <div>{item.category}</div>
                        <div>{item.geoJson.properties.address}</div>
                    </StItem>
                ))}
                <div ref={lastItemRef}></div>
            </StContent>
        </StDongItemListModal>
    );
};

const StDongItemListModal = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 100px;
    left: 0;
    width: 240px;
    max-width: 240px;
    overflow-y: visible;
    box-shadow: 1px 1px 1px #919191;
    padding: 30px 15px;
    border-radius: 2px;

    ${media.tablet`
    top: 50px;
    max-height: 520px;
    `}
`;

const StContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 650px;
    overflow-y: auto;

    ${media.tablet`
    max-height: 520px;
    `}
`;

const StCancelButton = styled.div`
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 15px;
    right: 18px;
    color: #8e00ee;

    &:hover {
        opacity: 0.5;
    }
`;

const StItem = styled.div<{ $source }>`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 18px 10px 10px;
    background-color: ${(props) => {
        if (props.$source === 'AUCTION') return '#FF427B';
        if (props.$source === 'ONBID') return '#278afb';
        if (props.$source === 'NAVER') return '#16AE53';
        if (props.$source.includes('RENT') !== -1) return '#ffc800';
        return 'transparent';
    }};
    font-size: 12px;
    color: white;
    border-radius: 4px;
    max-width: 180px;

    &:hover {
        opacity: 0.8;
    }
`;
