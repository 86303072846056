import React, { useState } from 'react';
import styled from 'styled-components';

import { media } from '../../../styles/mediaQueries';
import { postGeoJsons } from '../../../api/geoJsonApi';

export interface StModalProps {
    $isOpen: boolean;
}

export const DrawContainer = ({
    map,
    isDrawModalOpen,
    setIsDrawingModalOpen,
    handleFetchOsmWater,
    setIsFilterModalOpen,
    setIsPolygonListModalOpen,
    setIsBookmarkModalOpen,
    setIsNotificationModalOpen,
}) => {
    const enablePolygonDrawing = () => {
        if (!map) return;

        const manager = new naver.maps.drawing.DrawingManager({
            map: map,
            drawingMode: naver.maps.drawing.DrawingMode.POLYGON,
            drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
            polygonOptions: {
                fillColor: '#8e00ee',
                fillOpacity: 0.1,
                strokeWeight: 1,
                strokeColor: '#8e00ee',
                clickable: true,
                zIndex: 100,
            },
        });

        naver.maps.Event.addListener(manager, 'polygonAdded', (polygon) => {
            let name = prompt('영역의 이름을 입력하세요');

            if (!name) {
                name = '무제';
            }

            if (name) {
                // 폴리곤 좌표 추출 및 첫 번째 좌표를 마지막에 다시 추가
                const originalPaths = polygon
                    .getPaths()
                    .getArray()[0]
                    .getArray();
                const paths = originalPaths.map((coord) => [
                    coord.lng(),
                    coord.lat(),
                ]);
                // 폴리곤을 닫기 위해 첫 번째 좌표를 마지막에 추가
                paths.push(paths[0]);

                const data = {
                    type: 'Feature',
                    properties: {
                        name: name,
                        layer: '-1',
                        waterway: 'river',
                        note: '',
                        description: '',
                    },
                    geometry: {
                        type: 'Polygon',
                        coordinates: [paths],
                    },
                };

                postGeoJsons(data)
                    .then(() => {
                        alert('새로운 영역이 그려졌어요');
                    })
                    .then(() => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.error('영역 그리기 실패:', error);
                        alert('영역 그리기 실패');
                    });
            }
        });
    };

    const otherModalClose = () => {
        setIsFilterModalOpen(false);
        setIsPolygonListModalOpen(false);
        setIsBookmarkModalOpen(false);
        setIsNotificationModalOpen(false);
    };

    return (
        <StDrawContainer>
            <StDraw
                onClick={() => {
                    setIsDrawingModalOpen(!isDrawModalOpen);
                    otherModalClose();
                }}
                $isOpen={isDrawModalOpen}
            >
                ✏️
            </StDraw>
            {isDrawModalOpen && (
                <StDrawModal>
                    <StDrawButton onClick={enablePolygonDrawing}>
                        직접 그리기
                    </StDrawButton>
                    <StDrawButton onClick={handleFetchOsmWater}>
                        강 선택하기
                    </StDrawButton>
                </StDrawModal>
            )}
        </StDrawContainer>
    );
};

const StDrawContainer = styled.div`
    position: relative;
`;
const StDraw = styled.button<StModalProps>`
    background-color: ${(props) => (props.$isOpen ? '#8e00ee' : 'white')};
    color: ${(props) => (props.$isOpen ? 'white' : 'black')};
`;

const StDrawModal = styled.div`
    position: absolute;
    top: 0;
    right: 80px;
    display: flex;
    gap: 15px;
    background-color: white;
    box-shadow: 1px 1px 1px #919191;
    padding: 10px;
    border-radius: 2px;

    ${media.tablet`
    right: 50px;
    `}
`;

const StDrawButton = styled.div`
    cursor: pointer;
    padding: 10px;
    background-color: #8e00ee;
    color: white;
    border-radius: 4px;
    white-space: nowrap;

    &:hover {
        opacity: 0.7;
    }
`;
