import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MapTypeList } from './components/MapTypeList';

import { SearchBar } from './components/SearchBar';
import { useNavigate } from 'react-router-dom';
import { Detail } from './components/Detail';
import {
    getPropertiesDong,
    getPropertiesSido,
    getPropertiesSigungu,
    getPropertiesWhole,
} from '../../api/propertyApi';
import { ZoomHandler } from './components/ZoomHandler';
import { DrawContainer } from './components/DrawContainer';
import { handleLogout, isLogin } from '../../shared/TokenUtils/TokenUtils';
import { getOsmWater } from '../../api/osmJsonApi';
import { RiverModal } from './components/RiverModal';
import { getMyGeoJsons } from '../../api/geoJsonApi';
import { PolygonListContainer } from './components/PolygonListContainer';
import { FilterContainer } from './components/FilterContainer';
import { PropertyType } from './components/PropertyType';
import { BookmarkContainer } from './components/BookmarkContainer';
import { TbCurrentLocation } from 'react-icons/tb';
import { useQuery } from '@tanstack/react-query';
import { PanoramaModal } from './components/PanoramaModal';
import { MyPolygonItemListModal } from './components/MyPolygonItemListModal';
import { media } from '../../styles/mediaQueries';
import { DirectionsContainer } from './components/DirectionsContainer';
import { NotificationContainer } from './components/NotificationContainer';
import { set } from 'lodash';
import { SigunguItemListModal } from './components/SigunguItemListModal';
import { DongItemListModal } from './components/DongItemListModal';

export default function LandingPage() {
    const [map, setMap] = useState<naver.maps.Map | null>(null);
    const [cadastralLayer, setCadastralLayer] =
        useState<naver.maps.CadastralLayer | null>(null);
    const [streetLayer, setStreetLayer] =
        useState<naver.maps.StreetLayer | null>(null);
    const [isHybridVisible, setIsHybridVisible] = useState(false);
    const [isTerrainVisible, setIsTerrainVisible] = useState(false);
    const [isNormalVisible, setIsNormalVisible] = useState(true);
    const [isCadastralVisible, setIsCadastralVisible] = useState(false);
    const [isStreetVisible, setIsStreetVisible] = useState(false);
    const [markers, setMarkers] = useState<naver.maps.Marker[]>([]);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [isOpenRiverModal, setIsOpenRiverModal] = useState(false);
    const [selectedPolygon, setSelectedPolygon] = useState(null);
    const [isDrawModalOpen, setIsDrawingModalOpen] = useState(false);
    const [isPolygonListModalOpen, setIsPolygonListModalOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
    const [isNotificationModalOpen, setIsNotificationModalOpen] =
        useState(false);
    const [isDirectionsModalOpen, setIsDirectionsModalOpen] = useState(false);
    const [filterObject, setFilterObject] = useState({});
    const [selectedTags, setSelectedTags] = useState([]);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [isRiverSide, setIsRiverSide] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isFilteringLoading, setIsFilteringLoading] = useState(false);
    const [waterPolygons, setWaterPolygons] = useState([]);
    const [isDetailModlaOpen, setIsDetailModalOpen] = useState(false);
    const [isPanoramaModalOpen, setIsPanoramaModalOpen] = useState(false);
    const [panoramaCoord, setPanoramaCoord] = useState(null);
    const [isMyPolygonItemListModalOpen, setIsMyPolygonItemListModalOpen] =
        useState(false);
    const [selectedGeoJson, setSelectedGeoJson] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState({
        AUCTION: true,
        ONBID: true,
        NAVER: true,
        RENT_MONTHLY: true,
        RENT_CHARTER: true,
    });
    const [relatedSearchModalOpen, setRelatedSearchModalOpen] = useState(false);
    const [selectedSido, setSelectedSido] = useState('');
    const [selectedSigungu, setSelectedSigungu] = useState('');
    const [selectedDong, setSelectedDong] = useState('');
    const [isSigunguItemListModalOpen, setIsSigunguItemListModalOpen] =
        useState(false);
    const [isDongItemListModalOpen, setIsDongItemListModalOpen] =
        useState(false);

    console.log('filterObject', filterObject);

    useEffect(() => {
        if (map) {
            const clickListener = naver.maps.Event.addListener(
                map,
                'click',
                function (e) {
                    if (isStreetVisible) {
                        setPanoramaCoord(e.coord);
                        setIsPanoramaModalOpen(true);
                    }
                }
            );

            return () => {
                naver.maps.Event.removeListener(clickListener);
            };
        }
    }, [map, isStreetVisible]);

    const navigate = useNavigate();

    const moveToCurrentLocation = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentLocation = new window.naver.maps.LatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                    // 지도의 중심을 현재 위치로 이동
                    map.setCenter(currentLocation);
                    map.setZoom(20); // 원하는 줌 레벨로 설정

                    // 기존에 표시된 현재 위치 마커가 있다면 제거
                    if ((window as any).currentLocationMarker) {
                        (window as any).currentLocationMarker.setMap(null);
                    }

                    // 현재 위치에 새로운 마커 생성
                    (window as any).currentLocationMarker =
                        new window.naver.maps.Marker({
                            position: currentLocation,
                            map: map,
                            title: '현재 위치',
                            icon: {
                                content:
                                    '<img src="https://res.cloudinary.com/dsxqz8qce/image/upload/v1710322534/pin_lvgafe.png" alt="" style="width: 55px; height: 55px;">',
                                anchor: new window.naver.maps.Point(12, 12),
                            },
                        });
                },
                (error) => {
                    console.error('Geolocation error:', error);
                    alert('위치 정보를 가져올 수 없습니다.');
                }
            );
        } else {
            alert('이 브라우저에서는 Geolocation이 지원되지 않습니다.');
        }
    };

    const initMap = () => {
        let defaultCenter = new window.naver.maps.LatLng(
            37.4595704,
            127.105399
        );
        let defaultZoom = 14;

        const storedCenter = localStorage.getItem('mapCenter');
        const storedZoom = localStorage.getItem('mapZoom');

        if (storedCenter && storedZoom) {
            defaultCenter = new window.naver.maps.LatLng(
                JSON.parse(storedCenter)
            );
            defaultZoom = JSON.parse(storedZoom);
        }

        const mapOptions = {
            center: defaultCenter,
            zoom: defaultZoom,
        };

        const mapInstance = new window.naver.maps.Map('map', mapOptions);
        setMap(mapInstance);

        const cadastralLayerInstance = new window.naver.maps.CadastralLayer();
        setCadastralLayer(cadastralLayerInstance);

        const streetLayerInstance = new window.naver.maps.StreetLayer();
        setStreetLayer(streetLayerInstance);
    };

    const changeMapType = (mapTypeId: string, isVisible: boolean) => {
        if (!map) return;

        const type = mapTypeId as keyof typeof window.naver.maps.MapTypeId;
        if (isVisible) {
            map.setMapTypeId(window.naver.maps.MapTypeId[type]);
        } else {
            map.setMapTypeId(window.naver.maps.MapTypeId.NORMAL);
        }
    };

    const toggleNormal = () => {
        setIsNormalVisible(true);
        changeMapType('NORMAL', true);
        setIsTerrainVisible(false);
        setIsHybridVisible(false);
    };

    const toggleHybrid = () => {
        setIsHybridVisible(true);
        changeMapType('HYBRID', true);
        setIsNormalVisible(false);
        setIsTerrainVisible(false);
    };

    const toggleTerrain = () => {
        setIsTerrainVisible(true);
        changeMapType('TERRAIN', true);
        setIsNormalVisible(false);
        setIsHybridVisible(false);
    };

    const toggleCadastralLayer = () => {
        if (map && cadastralLayer) {
            const isVisible = !cadastralLayer.getMap();
            cadastralLayer.setMap(isVisible ? map : null);
            setIsCadastralVisible(isVisible);
        }
    };

    const toggleStreetLayer = () => {
        if (map && streetLayer) {
            const isVisible = !streetLayer.getMap();
            streetLayer.setMap(isVisible ? map : null);
            setIsStreetVisible(isVisible);
        }
    };

    const clearMarkers = async () => {
        markers.forEach((marker) => marker.setMap(null));
        setMarkers([]); // 마커 상태 초기화
    };

    const addMarker = (
        lat: number,
        lng: number,
        content: any,
        property: any
    ) => {
        const marker = new window.naver.maps.Marker({
            position: new window.naver.maps.LatLng(lat, lng),
            map: map,
            icon: {
                content: content,
                size: new window.naver.maps.Size(22, 34),
                anchor: new window.naver.maps.Point(11, 34),
            },
        });

        if (property.sido && !property.sigungu) {
            window.naver.maps.Event.addListener(marker, 'click', () => {
                const center = new window.naver.maps.LatLng(lat, lng);
                map.setCenter(center);
                map.setZoom(12);
            });
        } else if (property.sigungu && !property.dong) {
            window.naver.maps.Event.addListener(marker, 'click', () => {
                setSelectedSido(property.sido);
                setSelectedSigungu(property.sigungu);

                // isSigunguItemListModalOpen이 true일 경우, 모달을 잠시 닫았다가 다시 열기
                if (isSigunguItemListModalOpen) {
                    setIsSigunguItemListModalOpen(false);
                    // 모달을 다시 열기 위해 짧은 지연 후 상태를 true로 설정
                    setTimeout(() => setIsSigunguItemListModalOpen(true), 10);
                } else {
                    setIsSigunguItemListModalOpen(true);
                }

                setIsDetailModalOpen(false);
                setIsMyPolygonItemListModalOpen(false);
                setRelatedSearchModalOpen(false);
                setIsDongItemListModalOpen(false);

                if (map && property.coordinates.length !== 0) {
                    const [lng, lat] = property.coordinates;
                    map.setCenter(new naver.maps.LatLng(lat, lng));
                    map.setZoom(14);
                }
            });
        } else if (property.dong) {
            window.naver.maps.Event.addListener(marker, 'click', () => {
                setSelectedSido(property.sido);
                setSelectedSigungu(property.sigungu);
                setSelectedDong(property.dong);

                // isDongItemListModalOpen이 true일 경우, 모달을 잠시 닫았다가 다시 열기
                if (isDongItemListModalOpen) {
                    setIsDongItemListModalOpen(false);
                    // 모달을 다시 열기 위해 짧은 지연 후 상태를 true로 설정
                    setTimeout(() => setIsDongItemListModalOpen(true), 10);
                } else {
                    setIsDongItemListModalOpen(true);
                }

                setIsSigunguItemListModalOpen(false);
                setIsDetailModalOpen(false);
                setIsMyPolygonItemListModalOpen(false);
                setRelatedSearchModalOpen(false);

                if (map && property.coordinates.length !== 0) {
                    const [lat, lng] = property.coordinates;
                    map.setCenter(new naver.maps.LatLng(lat, lng));
                    map.setZoom(15);
                }
            });
        } else if (property.geoJson?.type === 'Point') {
            window.naver.maps.Event.addListener(marker, 'click', () => {
                setSelectedProperty(property);
                setIsDetailModalOpen(true);
                setIsMyPolygonItemListModalOpen(false);
                setRelatedSearchModalOpen(false);
                setIsSigunguItemListModalOpen(false);
                setIsDongItemListModalOpen(false);
            });
        }

        markers.push(marker);
    };

    const fetchAndDisplayProperties = async () => {
        setIsFilteringLoading(true);
        if (!map) return;

        const selectedSources = Object.values(selectedTypes).some(
            (value) => value
        );
        if (!selectedSources) {
            setIsFilteringLoading(false);
            return;
        }

        if (map) {
            const center = map.getCenter();
            const zoom = map.getZoom();

            localStorage.setItem('mapCenter', JSON.stringify(center));
            localStorage.setItem('mapZoom', JSON.stringify(zoom));
        }

        clearMarkers(); // 기존 마커를 지우는 함수 호출

        const bounds = map.getBounds() as naver.maps.LatLngBounds;
        const southWest = bounds.getSW();
        const northEast = bounds.getNE();

        const lx = southWest.lng();
        const rx = northEast.lng();
        const ty = northEast.lat();
        const by = southWest.lat();

        const zoom = map.getZoom();
        const isMobile = window.innerWidth <= 768;

        let sidoProperties = [];
        let sigunguProperties = [];
        let dongProperties = [];
        let wholePropertiesToDot = [];
        let wholeProperties = [];

        if (zoom <= 10) {
            sidoProperties = await getPropertiesSido(
                lx,
                rx,
                ty,
                by,
                filterObject
            );
        } else if (zoom <= 13) {
            sigunguProperties = await getPropertiesSigungu(
                lx,
                rx,
                ty,
                by,
                filterObject
            );
        } else if (zoom <= 14) {
            dongProperties = await getPropertiesDong(
                lx,
                rx,
                ty,
                by,
                filterObject
            );
        } else if (zoom === 15) {
            wholePropertiesToDot = await getPropertiesWhole(
                lx,
                rx,
                ty,
                by,
                filterObject
            );
        } else {
            wholeProperties = await getPropertiesWhole(
                lx,
                rx,
                ty,
                by,
                filterObject
            );
        }

        console.log(wholePropertiesToDot);
        console.log('sigungu', sigunguProperties);
        console.log('sido', sidoProperties);

        wholePropertiesToDot.forEach((property: any) => {
            let lat = property.geoJson.coordinates[1];
            let lng = property.geoJson.coordinates[0];

            if (lat && lng) {
                let markerColor = '';
                if (property.source === 'ONBID') {
                    markerColor = '#278afb';
                } else if (property.source === 'AUCTION') {
                    markerColor = '#FF427B';
                } else if (property.source === 'NAVER') {
                    markerColor = '#16AE53';
                } else if (property.source.includes('RENT') !== -1) {
                    markerColor = '#ffc800';
                }

                let content = `<div class="custom-overlay">        
                <div style=" cursor: pointer;  width: 15px; height: 15px;  background-color: ${markerColor}; border-radius: 50%; box-shadow: 1px 1px 2px #3b3b3b;" onclick="document.dispatchEvent(new CustomEvent('markerClick', {detail: {id: '${property._id}'}}))">                  
                </div>
            </div>`;

                addMarker(lat, lng, content, property);
            }
        });

        wholeProperties.forEach((property: any) => {
            let lat = property.geoJson.coordinates[1];
            let lng = property.geoJson.coordinates[0];

            if (lat && lng) {
                let markerColor = '';
                if (property.source === 'ONBID') {
                    markerColor = '#278afb';
                } else if (property.source === 'AUCTION') {
                    markerColor = '#FF427B';
                } else if (property.source === 'NAVER') {
                    markerColor = '#16AE53';
                } else if (property.source.includes('RENT') !== -1) {
                    markerColor = '#ffc800';
                }

                const createdAtDate = new Date(property.createdAt);
                const currentDate = new Date();
                const timeDiff =
                    currentDate.getTime() - createdAtDate.getTime();
                const daysDiff = timeDiff / (1000 * 60 * 60 * 24);
                const isNew =
                    daysDiff <= 7
                        ? `<div style="background-color: black; color: white; font-size: 10px; position: absolute; top: px; left: 2px; transform: translate(-50%, -50%); border-radius: 50%; padding: 3px 5px 3px 5px; z-index: 2;">N</div>`
                        : '';

                let content = `<div class="custom-overlay" style="position: relative; width: 100%; height: 100%;">
                ${isNew}
                <div style="clip-path: polygon(0 0, 100% 0%, 100% 81%, 13% 81%, 0 100%); cursor: pointer; white-space: nowrap; word-break: break-word;  display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 12px; color: white; width: 100%; height: 100%; padding: 5px 10px 10px 10px; background-color: ${markerColor}; border-radius: 5%; box-shadow: 1px 1px 2px #3b3b3b;" onclick="document.dispatchEvent(new CustomEvent('markerClick', {detail: {id: '${
                    property._id
                }'}}))">
                    <div style="z-index: 1;">${
                        property.source === 'ONBID'
                            ? property.category.split('/')[1]
                            : property.category
                    }</div>
                </div>
            </div>`;

                addMarker(lat, lng, content, property);
            }
        });

        console.log('wholeProperties', wholeProperties);

        dongProperties.forEach((property: any) => {
            let lat = property.coordinates[0];
            let lng = property.coordinates[1];

            let content = `<div class="custom-overlay" style="cursor:pointer; white-space: nowrap; display: flex; flex-direction: column; gap: 4px; justify-content: center; align-items: center; font-size: 12px; color: white; width: 100%; height: 100%; padding: 8px 6px; background-color: #8E00EE; border-radius: 5%; box-shadow: 1px 1px 2px #3b3b3b;"><div>${property.dong}</div><div>${property.propertyCount}</div></div>`;

            addMarker(lat, lng, content, property);
        });

        sigunguProperties.forEach((property: any) => {
            let lat = property.coordinates[1];
            let lng = property.coordinates[0];

            let content = `<div class="custom-overlay" style="cursor:pointer; white-space: nowrap; display: flex; flex-direction: column; gap: 5px; justify-content: center; align-items: center; font-size: 14px; color: white; width: 100%; height: 100%; padding: 10px 8px; background-color: #8E00EE; border-radius: 5%; box-shadow: 1px 1px 2px #3b3b3b;"><div>${property.sigungu}</div><div>${property.propertyCount}</div></div>`;

            addMarker(lat, lng, content, property);
        });

        sidoProperties.forEach((property: any) => {
            let lat = property.coordinates[1];
            let lng = property.coordinates[0];

            let content = `<div class="custom-overlay" style="cursor:pointer; white-space: nowrap; display: flex; flex-direction: column; gap: 5px; justify-content: center; align-items: center; font-size: 14px; color: white; width: 100%; height: 100%; padding: 10px 8px; background-color: #8E00EE; border-radius: 5%; box-shadow: 1px 1px 2px #3b3b3b;"><div>${property.sido}</div><div>${property.propertyCount}</div></div>`;

            addMarker(lat, lng, content, property);
        });
        setIsFilteringLoading(false);
    };

    useEffect(() => {
        let dragendListener: any;
        let debounceTimer: any;

        const debounce = (callback: Function, delay: number) => {
            return (...args: any) => {
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => {
                    callback(...args);
                }, delay);
            };
        };

        if (map) {
            const debouncedFetchAndDisplayProperties = debounce(
                fetchAndDisplayProperties,
                300
            );
            dragendListener = naver.maps.Event.addListener(
                map,
                'bounds_changed',
                debouncedFetchAndDisplayProperties
            );

            fetchAndDisplayProperties();
        }

        return () => {
            clearMarkers();

            if (dragendListener) {
                naver.maps.Event.removeListener(dragendListener);
            }
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
        };
    }, [map, filterObject]);

    const handleFetchOsmWater = async () => {
        if (!map) return;
        const currentZoom = map.getZoom();

        if (currentZoom >= 14) {
            const bounds = map.getBounds() as naver.maps.LatLngBounds;
            const southWest = bounds.getSW();
            const northEast = bounds.getNE();

            const lx = southWest.lng();
            const rx = northEast.lng();
            const ty = northEast.lat();
            const by = southWest.lat();

            const waterGeometriesResponse = await getOsmWater(lx, rx, ty, by);
            const waterGeometries = waterGeometriesResponse.map(
                (geometry) => geometry.geometry
            );

            // 기존 폴리곤을 지도에서 제거합니다.
            clearWaterPolygons();

            // 각 지오메트리 타입에 맞게 지도에 폴리곤을 표시합니다.
            const newWaterPolygons =
                displayWaterGeometriesOnMap(waterGeometries);
            setWaterPolygons(newWaterPolygons); // 새로운 폴리곤 배열로 상태를 업데이트합니다.
        } else {
            alert('지도를 더 확대한 뒤에 사용해주세요.');
        }
    };

    const clearWaterPolygons = () => {
        waterPolygons.forEach((polygon) => polygon.setMap(null)); // 기존 폴리곤들을 지도에서 제거합니다.
        setWaterPolygons([]); // 폴리곤 배열을 초기화합니다.
    };

    const displayWaterGeometriesOnMap = (geometries) => {
        return geometries.map((geometry) => {
            let polygonPath = [];

            if (geometry.type === 'Polygon') {
                polygonPath = geometry.coordinates[0].map(
                    (coord) => new naver.maps.LatLng(coord[1], coord[0])
                );
            } else if (geometry.type === 'MultiPolygon') {
                polygonPath = geometry.coordinates[0][0].map(
                    (coord) => new naver.maps.LatLng(coord[1], coord[0])
                );
            }

            const polygon = new naver.maps.Polygon({
                map: map,
                paths: polygonPath,
                fillColor: '#8e00ee',
                fillOpacity: 0.1,
                strokeColor: '#8e00ee',
                strokeOpacity: 0.6,
                strokeWeight: 1,
                clickable: true,
            });
            naver.maps.Event.addListener(polygon, 'mouseover', () => {
                document.body.style.cursor = 'pointer';
                (polygon as any).setOptions({ fillOpacity: 0.6 });
            });

            naver.maps.Event.addListener(polygon, 'mouseout', () => {
                document.body.style.cursor = 'default';
                (polygon as any).setOptions({ fillOpacity: 0.1 });
            });

            naver.maps.Event.addListener(polygon, 'click', () => {
                setSelectedPolygon({
                    type: geometry.type,
                    coordinates: geometry.coordinates,
                });
                setIsOpenRiverModal(true);
            });

            return polygon; // 새로 생성된 폴리곤을 반환
        });
    };

    useEffect(() => {
        initMap();
    }, []);

    const { data: myGeoJsons, isSuccess } = useQuery({
        queryKey: ['myGeoJsons'],
        queryFn: getMyGeoJsons,
    });

    const displayMyGeometriesOnMap = (geometries) => {
        console.log('myGeoMetries', geometries);
        geometries.forEach((geometry) => {
            let polygonPath;
            switch (geometry.geometry.type) {
                case 'Polygon':
                    polygonPath = geometry.geometry.coordinates[0].map(
                        (coord) => new naver.maps.LatLng(coord[1], coord[0])
                    );
                    break;
                default:
                    console.log('Unsupported geometry type');
                    return;
            }
            const polygon = new naver.maps.Polygon({
                map: map,
                paths: polygonPath,
                fillColor: '#8e00ee',
                fillOpacity: 0.1,
                strokeColor: '#8e00ee',
                strokeOpacity: 0.6,
                strokeWeight: 1,
                clickable: true,
            });

            // 마우스 호버 시 커서 변경
            naver.maps.Event.addListener(polygon, 'mouseover', () => {
                document.body.style.cursor = 'pointer';
                (polygon as any).setOptions({
                    fillOpacity: 0.6,
                });
            });
            naver.maps.Event.addListener(polygon, 'mouseout', () => {
                document.body.style.cursor = 'default'; // 커서 스타일을 기본으로 변경
                (polygon as any).setOptions({
                    fillOpacity: 0.1,
                });
            });
            naver.maps.Event.addListener(polygon, 'click', () => {
                setIsDongItemListModalOpen(false);
                setIsSigunguItemListModalOpen(false);
                setIsDetailModalOpen(false);
                setRelatedSearchModalOpen(false);
                setSelectedGeoJson(geometry);
                setIsMyPolygonItemListModalOpen(true);
            });
        });
    };

    useEffect(() => {
        if (map && isSuccess) {
            displayMyGeometriesOnMap(myGeoJsons);
        }
    }, [isSuccess, myGeoJsons]);

    return (
        <StLandingPage>
            <StMapContainer id='map'></StMapContainer>
            <SearchBar
                map={map}
                relatedSearchModalOpen={relatedSearchModalOpen}
                setRelatedSearchModalOpen={setRelatedSearchModalOpen}
                setIsDetailModalOpen={setIsDetailModalOpen}
                setIsMyPolygonItemListModalOpen={
                    setIsMyPolygonItemListModalOpen
                }
                setIsSigunguItemListModalOpen={setIsSigunguItemListModalOpen}
                setIsDongItemListModalOpen={setIsDongItemListModalOpen}
            />
            {isDetailModlaOpen && (
                <Detail
                    setIsDetailModalOpen={setIsDetailModalOpen}
                    propertyId={selectedProperty._id}
                />
            )}
            {isMyPolygonItemListModalOpen && (
                <MyPolygonItemListModal
                    selectedGeoJson={selectedGeoJson}
                    setIsMyPolygonItemListModalOpen={
                        setIsMyPolygonItemListModalOpen
                    }
                    setSelectedProperty={setSelectedProperty}
                    setIsDetailModalOpen={setIsDetailModalOpen}
                />
            )}
            {isSigunguItemListModalOpen && (
                <SigunguItemListModal
                    filterObject={filterObject}
                    selectedSido={selectedSido}
                    selectedSigungu={selectedSigungu}
                    setIsSigunguItemListModalOpen={
                        setIsSigunguItemListModalOpen
                    }
                    setIsDetailModalOpen={setIsDetailModalOpen}
                    setSelectedProperty={setSelectedProperty}
                    map={map}
                />
            )}
            {isDongItemListModalOpen && (
                <DongItemListModal
                    filterObject={filterObject}
                    selectedSido={selectedSido}
                    selectedSigungu={selectedSigungu}
                    selectedDong={selectedDong}
                    setIsDongItemListModalOpen={setIsDongItemListModalOpen}
                    setIsDetailModalOpen={setIsDetailModalOpen}
                    setSelectedProperty={setSelectedProperty}
                    map={map}
                />
            )}
            <MapTypeList
                isNormalVisible={isNormalVisible}
                isHybridVisible={isHybridVisible}
                isTerrainVisible={isTerrainVisible}
                isCadastralVisible={isCadastralVisible}
                isStreetVisible={isStreetVisible}
                toggleNormal={toggleNormal}
                toggleHybrid={toggleHybrid}
                toggleTerrain={toggleTerrain}
                toggleCadastralLayer={toggleCadastralLayer}
                toggleStreetLayer={toggleStreetLayer}
            />
            <StRightButtonContainer>
                <StLoginHandler>
                    {isLogin() ? (
                        <div onClick={handleLogout}>logout</div>
                    ) : (
                        <div onClick={() => navigate('/login')}>login</div>
                    )}
                </StLoginHandler>
                <StMyLocation onClick={moveToCurrentLocation}>
                    <TbCurrentLocation />
                </StMyLocation>
                <ZoomHandler map={map} />
                <PropertyType
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    isFilteringLoading={isFilteringLoading}
                    setSelectedTypes={setSelectedTypes}
                    selectedTypes={selectedTypes}
                />
                <FilterContainer
                    isFilterModalOpen={isFilterModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxPrice={setMaxPrice}
                    isFilteringLoading={isFilteringLoading}
                    setIsDrawingModalOpen={setIsDrawingModalOpen}
                    setIsPolygonListModalOpen={setIsPolygonListModalOpen}
                    setIsBookmarkModalOpen={setIsBookmarkModalOpen}
                    isRiverSide={isRiverSide}
                    setIsRiverSide={setIsRiverSide}
                    setIsNotificationModalOpen={setIsNotificationModalOpen}
                    isFeatured={isFeatured}
                    setIsFeatured={setIsFeatured}
                />
                <DrawContainer
                    map={map}
                    isDrawModalOpen={isDrawModalOpen}
                    setIsDrawingModalOpen={setIsDrawingModalOpen}
                    handleFetchOsmWater={handleFetchOsmWater}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    setIsPolygonListModalOpen={setIsPolygonListModalOpen}
                    setIsBookmarkModalOpen={setIsBookmarkModalOpen}
                    setIsNotificationModalOpen={setIsNotificationModalOpen}
                />
                <PolygonListContainer
                    map={map}
                    myGeoJsons={myGeoJsons}
                    isPolygonListModalOpen={isPolygonListModalOpen}
                    setIsPolygonListModalOpen={setIsPolygonListModalOpen}
                    setIsDrawingModalOpen={setIsDrawingModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    setIsBookmarkModalOpen={setIsBookmarkModalOpen}
                    setIsNotificationModalOpen={setIsNotificationModalOpen}
                />
                <BookmarkContainer
                    map={map}
                    isBookmarkModalOpen={isBookmarkModalOpen}
                    setIsBookmarkModalOpen={setIsBookmarkModalOpen}
                    fetchAndDisplayProperties={fetchAndDisplayProperties}
                    setIsDrawingModalOpen={setIsDrawingModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    setIsPolygonListModalOpen={setIsPolygonListModalOpen}
                    clearMarkers={clearMarkers}
                    setIsNotificationModalOpen={setIsNotificationModalOpen}
                />
                <NotificationContainer
                    map={map}
                    setIsNotificationModalOpen={setIsNotificationModalOpen}
                    isNotificationModalOpen={isNotificationModalOpen}
                    setIsDrawingModalOpen={setIsDrawingModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    setIsPolygonListModalOpen={setIsPolygonListModalOpen}
                    setIsBookmarkModalOpen={setIsBookmarkModalOpen}
                />
                {/* <DirectionsContainer
                    map={map}
                    isDirectionsModalOpen={isDirectionsModalOpen}
                    setIsDirectionsModalOpen={setIsDirectionsModalOpen}
                    setIsDrawingModalOpen={setIsDrawingModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                    setIsPolygonListModalOpen={setIsPolygonListModalOpen}
                    setIsBookmarkModalOpen={setIsBookmarkModalOpen}
                /> */}
            </StRightButtonContainer>

            {isOpenRiverModal && (
                <RiverModal
                    map={map}
                    selectedPolygon={selectedPolygon}
                    setSelectedPolygon={setSelectedPolygon}
                    setIsOpenRiverModal={setIsOpenRiverModal}
                />
            )}

            {isPanoramaModalOpen && (
                <PanoramaModal
                    map={map}
                    panoramaCoord={panoramaCoord}
                    setIsPanoramaModalOpen={setIsPanoramaModalOpen}
                />
            )}
        </StLandingPage>
    );
}

const StLandingPage = styled.div`
    width: 100dvw;
    height: 100dvh;
    position: relative;

    ${media.tablet`
        
    `}

    ${media.mobile`
    
    `}
`;

const StMapContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const StMyLocation = styled.button`
    font-size: 16px;
    background-color: white;
    color: black;
`;

const StRightButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 20px;
    gap: 20px;
    z-index: 90;

    ${media.tablet`
    top: 0;
    right: 0px;
    `}

    button {
        border: none;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        box-shadow: 1px 1px 1px #919191;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }
`;

const StLoginHandler = styled.button`
    color: black;
    background-color: white;
    font-size: 13px;
`;
