import React from 'react';
import Router from './router/Router';
import { RecoilProvider } from './providers/RecoilProvider';
import { GlobalStyle } from './styles/GlobalStyle';
import { TanstackQueryProvider } from './providers/TanstackQueryProvider';
import { ThemeProvider } from 'styled-components';
import { Theme } from './styles/theme';

function App() {
    return (
        <>
            <GlobalStyle />
            <RecoilProvider>
                <ThemeProvider theme={Theme}>
                    <TanstackQueryProvider>
                        <Router />
                    </TanstackQueryProvider>
                </ThemeProvider>
            </RecoilProvider>
        </>
    );
}

export default App;
