import React, { useEffect } from 'react';
import styled from 'styled-components';

export const PanoramaModal = ({
    map,
    panoramaCoord,
    setIsPanoramaModalOpen,
}) => {
    useEffect(() => {
        if (panoramaCoord && window.naver) {
            const panoOptions = {
                position: new window.naver.maps.LatLng(
                    panoramaCoord.lat(),
                    panoramaCoord.lng()
                ),
                pov: {
                    pan: 0,
                    tilt: 0,
                    fov: 100,
                },
                zoom: 1,
            };

            new window.naver.maps.Panorama('panorama', panoOptions);
        }
    }, [panoramaCoord]);

    return (
        <StPanoramaModal>
            <div
                id='panorama'
                style={{ width: '100vw', height: '100vh' }}
            ></div>
            <p onClick={() => setIsPanoramaModalOpen(false)}>X</p>
        </StPanoramaModal>
    );
};

const StPanoramaModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 98;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    p {
        cursor: pointer;
        color: white;
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 50px;
        border: none;
    }
`;
