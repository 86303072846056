import { Route, Routes } from 'react-router-dom';
import React from 'react';
import LandingPage from '../pages/LandingPage/LandingPage';
import LoginPage from '../pages/LoginPage/LoginPage';
import GoogleRedirectPage from '../pages/LoginPage/GoogleRedirectPage';
import ProtectedRoute from './ProtectedRoute';

export default function Router() {
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <ProtectedRoute>
                        <LandingPage />
                    </ProtectedRoute>
                }
            />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/auth' element={<GoogleRedirectPage />} />
        </Routes>
    );
}
