import React from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../../styles/mediaQueries';
import { getMyGeoJsonItems } from '../../../api/geoJsonApi';
import { useQuery } from '@tanstack/react-query';
import { set } from 'lodash';
import { MdOutlineCancel } from 'react-icons/md';

export const MyPolygonItemListModal = ({
    selectedGeoJson,
    setIsMyPolygonItemListModalOpen,
    setSelectedProperty,
    setIsDetailModalOpen,
}) => {
    const { data: myGeoJsonItems, isLoading } = useQuery({
        queryKey: [
            'getMyGeoJsonItems',
            {
                geoJson: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: selectedGeoJson?.geometry.coordinates,
                    },
                },
            },
        ],
        queryFn: () =>
            getMyGeoJsonItems({
                geoJson: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: selectedGeoJson?.geometry.coordinates,
                    },
                },
            }),
        enabled: !!selectedGeoJson,
    });

    console.log(myGeoJsonItems);

    const onClickItem = (item) => {
        setIsDetailModalOpen(true);
        setIsMyPolygonItemListModalOpen(false);
        setSelectedProperty(item);
    };

    return (
        <StMyPolygonItemListModal>
            <StCancelButton
                onClick={() => {
                    setIsMyPolygonItemListModalOpen(false);
                }}
            >
                <MdOutlineCancel />
            </StCancelButton>
            {isLoading ? (
                <Spinner />
            ) : myGeoJsonItems?.length === 0 ? (
                <StEmpty>리스트가 비어있습니다.</StEmpty>
            ) : (
                <>
                    {myGeoJsonItems?.map((item, i) => (
                        <StItem
                            key={i}
                            $source={item.source}
                            onClick={() => onClickItem(item)}
                        >
                            <div>{item.category}</div>
                            <div>{item.geoJson.properties.address}</div>
                        </StItem>
                    ))}
                </>
            )}
        </StMyPolygonItemListModal>
    );
};

const StMyPolygonItemListModal = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    position: absolute;
    top: 100px;
    left: 0;
    width: 240px;
    max-width: 240px;
    max-height: 650px;
    overflow-y: auto;
    box-shadow: 1px 1px 1px #919191;
    padding: 40px 15px 20px 15px;
    border-radius: 2px;

    ${media.tablet`
    top: 50px;
    max-height: 520px;
    `}
`;

const StCancelButton = styled.div`
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 15px;
    right: 18px;
    color: #8e00ee;

    &:hover {
        opacity: 0.5;
    }
`;

const StItem = styled.div<{ $source }>`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 18px 10px 10px;
    background-color: ${(props) => {
        if (props.$source === 'AUCTION') return '#FF427B';
        if (props.$source === 'ONBID') return '#278afb';
        if (props.$source === 'NAVER') return '#16AE53';
        if (props.$source.includes('RENT') !== -1) return '#ffc800';
        return 'transparent';
    }};
    font-size: 12px;
    color: white;
    border-radius: 4px;
    max-width: 180px;

    &:hover {
        opacity: 0.8;
    }
`;

const StEmpty = styled.div`
    margin-left: 20px;
    margin-top: 20px;
    color: grey;
    font-size: 14px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 10px solid #f3f3f3;
    border-top: 10px solid #8e00ee;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite;

    margin: 20px 80px;
`;
