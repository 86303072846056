import React from 'react';
import styled from 'styled-components';
import { PolygonListModal } from './PolygonListModal';
import { StModalProps } from './DrawContainer';

export const PolygonListContainer = ({
    isPolygonListModalOpen,
    setIsPolygonListModalOpen,
    myGeoJsons,
    setIsDrawingModalOpen,
    setIsFilterModalOpen,
    setIsBookmarkModalOpen,
    map,
    setIsNotificationModalOpen,
}) => {
    const otherModalClose = () => {
        setIsDrawingModalOpen(false);
        setIsFilterModalOpen(false);
        setIsBookmarkModalOpen(false);
        setIsNotificationModalOpen(false);
    };
    return (
        <StPolygonListContainer>
            <StPolygons
                onClick={() => {
                    setIsPolygonListModalOpen(!isPolygonListModalOpen);
                    otherModalClose();
                }}
                $isOpen={isPolygonListModalOpen}
            >
                영역
            </StPolygons>
            {isPolygonListModalOpen && (
                <PolygonListModal myGeoJsons={myGeoJsons} map={map} />
            )}
        </StPolygonListContainer>
    );
};

const StPolygonListContainer = styled.div`
    position: relative;
`;

const StPolygons = styled.button<StModalProps>`
    white-space: nowrap;
    background-color: ${(props) => (props.$isOpen ? '#8e00ee' : 'white')};
    color: ${(props) => (props.$isOpen ? 'white' : 'black')};
`;
