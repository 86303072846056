export const saveJwt = (jwt: string) => {
    localStorage.setItem('jwt', jwt);
};

export const getAccessToken = () => {
    return localStorage?.getItem('accessToken');
};

export const getJwt = () => {
    return localStorage?.getItem('jwt');
};

export const saveRefreshToken = (refreshToken: string) => {
    return localStorage.setItem('refreshToken', refreshToken);
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};

export const handleLogout = () => {
    // localStorage.removeItem('jwt');
    // localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    window.location.replace('/');
};

export const isLogin = () => {
    const accessToken = getAccessToken();
    return !!accessToken;
};
