import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
    ${reset}  

    *, *::before, *::after {
        /* box-sizing: border-box; */
        font-family: 'Pretendard', sans-serif;       
    }  

    html {
        
    }

    body {   
        font-family: 'Pretendard', sans-serif;
    }

    input {
        padding: 0 8px 0 8px;
    }

    input:focus {
        outline: none;
        box-shadow: 0 0 0 2px #8f62f4; 
    }

    a {
    color: inherit;
    text-decoration: none;
  }

`;
