import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaRegStar } from 'react-icons/fa6';
import { StModalProps } from './DrawContainer';
import {
    getMyBookmarks,
    patchBookmark,
    patchBookmarkMemo,
} from '../../../api/propertyApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { media } from '../../../styles/mediaQueries';

export interface PatchBookmarkMemoArgs {
    propertyId: string;
    newMemo: string;
}

const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return `${date.getFullYear()}년 ${
        date.getMonth() + 1
    }월 ${date.getDate()}일`;
};

export const BookmarkContainer = ({
    map,
    isBookmarkModalOpen,
    setIsBookmarkModalOpen,
    fetchAndDisplayProperties,
    setIsDrawingModalOpen,
    setIsFilterModalOpen,
    setIsPolygonListModalOpen,
    clearMarkers,
    setIsNotificationModalOpen,
}) => {
    const otherModalClose = () => {
        setIsDrawingModalOpen(false);
        setIsFilterModalOpen(false);
        setIsPolygonListModalOpen(false);
        setIsNotificationModalOpen(false);
    };
    const queryClient = useQueryClient();

    const { data: myBookmarks, refetch } = useQuery({
        queryKey: ['myBookmarks'],
        queryFn: getMyBookmarks,
    });

    console.log(myBookmarks);

    const editMemoMutation = useMutation({
        mutationFn: ({ propertyId, newMemo }: PatchBookmarkMemoArgs) =>
            patchBookmarkMemo(propertyId, newMemo),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['myBookmarks'] });
        },
    });

    const handleMemoEdit = async (propertyId: any, oldMemo: any) => {
        const newMemo = prompt('새 메모를 입력하세요:', oldMemo);
        if (newMemo !== null) {
            editMemoMutation.mutate({ propertyId, newMemo });
        }
    };

    const deleteBookmarkMutation = useMutation({
        mutationFn: (propertyId) => patchBookmark(propertyId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['property'] });
            queryClient.invalidateQueries({ queryKey: ['myBookmarks'] });
        },
    });

    const handleDelete = (propertyId) => {
        const isConfirmed = window.confirm('정말로 삭제하시겠습니까?');
        if (isConfirmed) {
            deleteBookmarkMutation.mutate(propertyId);
        }
    };

    const handleItemClick = (coordinates) => {
        if (map && coordinates.length !== 0) {
            const [lng, lat] = coordinates;
            map.setCenter(new naver.maps.LatLng(lat, lng));
            map.setZoom(19);
        }
    };

    const bookmarksWithUniqueDate = myBookmarks?.reduce(
        (acc, myBookmark, index, array) => {
            const currentDate = formatDate(myBookmark.createdAt);

            const isSameDate =
                index > 0 &&
                currentDate === formatDate(array[index - 1].createdAt);

            if (!isSameDate) {
                acc.push({ ...myBookmark, displayDate: currentDate });
            } else {
                acc.push({ ...myBookmark, displayDate: null });
            }

            return acc;
        },
        []
    );

    console.log('myBookmarks', myBookmarks);

    return (
        <StBookmarkContainer>
            <StBookmark
                onClick={() => {
                    setIsBookmarkModalOpen(!isBookmarkModalOpen);
                    otherModalClose();
                }}
                $isOpen={isBookmarkModalOpen}
            >
                <FaRegStar />
            </StBookmark>
            {isBookmarkModalOpen && (
                <StBookmarkModal>
                    <h2>내가 즐겨찾기한 매물</h2>
                    <StItemList>
                        {bookmarksWithUniqueDate?.length > 0 ? (
                            bookmarksWithUniqueDate.map((myBookmark) => (
                                <StItemContainer key={myBookmark.property._id}>
                                    {myBookmark.displayDate && (
                                        <StDate>
                                            {myBookmark.displayDate}
                                        </StDate>
                                    )}
                                    <StItemBox>
                                        <StItem
                                            $source={myBookmark.property.source}
                                            onClick={() =>
                                                handleItemClick(
                                                    myBookmark.property.geoJson
                                                        .coordinates
                                                )
                                            }
                                        >
                                            <StMemo>
                                                " {myBookmark.memo} "
                                            </StMemo>
                                            <StCategory>
                                                {myBookmark.property.category}
                                            </StCategory>
                                            <StAddress>
                                                {
                                                    myBookmark.property.geoJson
                                                        .properties.address
                                                }
                                            </StAddress>
                                        </StItem>
                                        <StModifyContainer>
                                            <StModify
                                                onClick={() =>
                                                    handleMemoEdit(
                                                        myBookmark.property._id,
                                                        myBookmark.memo
                                                    )
                                                }
                                            >
                                                수정
                                            </StModify>
                                            <StDelete
                                                onClick={() =>
                                                    handleDelete(
                                                        myBookmark.property._id
                                                    )
                                                }
                                            >
                                                삭제
                                            </StDelete>
                                        </StModifyContainer>
                                    </StItemBox>
                                </StItemContainer>
                            ))
                        ) : (
                            <StEmpty>리스트가 비어있습니다.</StEmpty>
                        )}
                    </StItemList>
                </StBookmarkModal>
            )}
        </StBookmarkContainer>
    );
};

const StBookmarkContainer = styled.div`
    position: relative;
`;

const StBookmark = styled.button<StModalProps>`
    background-color: ${(props) => (props.$isOpen ? '#8e00ee' : 'white')};
    color: ${(props) => (props.$isOpen ? 'white' : 'black')};
`;

const StBookmarkModal = styled.div`
    position: absolute;
    top: -100px;
    right: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 30px 15px;
    z-index: 90;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #919191;
    width: 250px;
    max-height: 200px;
    overflow: auto;

    h2 {
        display: block;
        font-weight: bold;
        font-size: 18px;
    }

    ${media.tablet`
        right: 50px;
        top: -40px;
        max-height: 100px;
    `}
`;

const StItemList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const StItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const StDate = styled.div`
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: grey;
    margin-bottom: 5px;
`;

const StItemBox = styled.div`
    gap: 5px;
    display: flex;
`;

const StItem = styled.div<{ $source }>`
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 18px 10px 10px;
    background-color: ${(props) => {
        if (props.$source === 'AUCTION') return '#FF427B';
        if (props.$source === 'ONBID') return '#278afb';
        if (props.$source === 'NAVER') return '#16AE53';
        if (props.$source.includes('RENT') !== -1) return '#ffc800';
        return '#8e00ee';
    }};
    font-size: 12px;
    color: white;
    border-radius: 4px;
    max-width: 180px;

    &:hover {
        opacity: 0.8;
    }
`;

const StModifyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
`;

const StModify = styled.div`
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #6c6c6c;
    color: #6c6c6c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 2px 5px;

    &:hover {
        opacity: 0.7;
    }
`;

const StDelete = styled.div`
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #ff4a68;
    color: #ff4a68;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 2px 5px;

    &:hover {
        opacity: 0.7;
    }
`;

const StMemo = styled.p`
    font-size: 12px;
    font-weight: bold;
`;

const StCategory = styled.p``;

const StAddress = styled.p``;

const StEmpty = styled.div`
    margin-left: 20px;
    margin-top: 20px;
    color: grey;
    font-size: 14px;
`;
