import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoSearchOutline } from 'react-icons/io5';
import { getJuso } from '../../../api/searchApi';
import { useQuery } from '@tanstack/react-query';
import { media } from '../../../styles/mediaQueries';

export const SearchBar = ({
    map,
    relatedSearchModalOpen,
    setRelatedSearchModalOpen,
    setIsDetailModalOpen,
    setIsMyPolygonItemListModalOpen,
    setIsSigunguItemListModalOpen,
    setIsDongItemListModalOpen,
}) => {
    const [address, setAddress] = useState('');
    const [searchTrigger, setSearchTrigger] = useState(false); // 검색을 트리거하는 상태 추가

    useEffect(() => {
        // address 상태가 변경될 때마다 실행되도록 useEffect 내에서 handleSearch 호출
        if (searchTrigger) {
            handleSearch();
            setSearchTrigger(false); // 검색 후 검색 트리거를 다시 false로 설정
        }
    }, [searchTrigger]); // 의존성 배열에 searchTrigger 추가

    const handleSearch = () => {
        if (!address) return; // 주소가 비어있으면 검색을 실행하지 않음
        naver.maps.Service.geocode({ query: address }, (status, response) => {
            if (
                status !== naver.maps.Service.Status.OK ||
                !response.v2.addresses.length
            ) {
                alert('검색 결과가 없습니다.');
                return;
            }

            const { x, y } = response.v2.addresses[0];
            const coords = new naver.maps.LatLng(parseFloat(y), parseFloat(x));
            map.setCenter(coords);
            map.setZoom(15);
            setRelatedSearchModalOpen(false);
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setSearchTrigger(true);
        }
    };

    const { data: relatedJuso } = useQuery({
        queryKey: ['getJuso', address],
        queryFn: () => getJuso(address),
    });

    useEffect(() => {
        // address에 값이 있으면 relatedSearchModalOpen을 true로 설정
        if (relatedJuso) {
            setRelatedSearchModalOpen(true);
            setIsDetailModalOpen(false);
            setIsMyPolygonItemListModalOpen(false);
            setIsSigunguItemListModalOpen(false);
            setIsDongItemListModalOpen(false);
        } else {
            // address에 값이 없으면 relatedSearchModalOpen을 false로 설정
            setRelatedSearchModalOpen(false);
        }
    }, [relatedJuso, setRelatedSearchModalOpen]);

    const onClickJuso = async (juso) => {
        setAddress(juso);
        setSearchTrigger(true);
    };

    return (
        <>
            <StContainer>
                <StSearchBarContainer>
                    <StSearchBar
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder='지역으로 검색'
                    />
                    <StIconWrapper onClick={handleSearch}>
                        <IoSearchOutline />
                    </StIconWrapper>
                </StSearchBarContainer>
            </StContainer>
            {relatedSearchModalOpen && (
                <StRelatedSearchModal>
                    {relatedJuso?.map((juso, i) => (
                        <StJusoContainer
                            key={i}
                            onClick={() => onClickJuso(juso.roadAddrPart1)}
                        >
                            <StJibunJuso>{juso.jibunAddr}</StJibunJuso>
                            <StRoadJuso>({juso.roadAddrPart1})</StRoadJuso>
                        </StJusoContainer>
                    ))}
                </StRelatedSearchModal>
            )}
        </>
    );
};

const StContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`;

const StSearchBarContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #919191;
    width: 270px;
    height: 40px;
`;

const StSearchBar = styled.input`
    background-color: white;
    border: none;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    padding: 0 50px 0 30px;

    ::placeholder {
        color: #919191;
    }
`;

const StIconWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    right: 20px;

    &:hover {
        opacity: 0.7;
    }
`;

const StRelatedSearchModal = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    position: absolute;
    top: 50px;
    left: 0;
    width: 240px;
    max-width: 240px;
    max-height: 650px;
    overflow-y: auto;
    box-shadow: 1px 1px 1px #919191;
    padding: 15px 15px;
    border-radius: 2px;
    z-index: 90;

    ${media.tablet`
    max-height: 520px;
    `}
`;

const StJusoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    letter-spacing: -1px;

    &:hover {
        cursor: pointer;
        opacity: 0.6;
    }
`;

const StJibunJuso = styled.div``;

const StRoadJuso = styled.div`
    font-weight: 300;
`;
