import React from 'react';
import styled from 'styled-components';
import { StModalProps } from './DrawContainer';
import { FilterModal } from './FilterModal';

export const FilterContainer = ({
    isFilterModalOpen,
    setIsFilterModalOpen,
    filterObject,
    setFilterObject,
    selectedTags,
    setSelectedTags,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    isFilteringLoading,
    setIsDrawingModalOpen,
    setIsPolygonListModalOpen,
    setIsBookmarkModalOpen,
    isRiverSide,
    setIsRiverSide,
    setIsNotificationModalOpen,
    isFeatured,
    setIsFeatured,
}) => {
    const otherModalClose = () => {
        setIsDrawingModalOpen(false);
        setIsPolygonListModalOpen(false);
        setIsBookmarkModalOpen(false);
        setIsNotificationModalOpen(false);
    };

    return (
        <StFilterContainer>
            <StFilter
                onClick={() => {
                    setIsFilterModalOpen(!isFilterModalOpen);
                    otherModalClose();
                }}
                $isOpen={isFilterModalOpen}
            >
                필터
            </StFilter>
            {isFilterModalOpen && (
                <FilterModal
                    filterObject={filterObject}
                    setFilterObject={setFilterObject}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    minPrice={minPrice}
                    setMinPrice={setMinPrice}
                    maxPrice={maxPrice}
                    setMaxPrice={setMaxPrice}
                    isFilteringLoading={isFilteringLoading}
                    isRiverSide={isRiverSide}
                    setIsRiverSide={setIsRiverSide}
                    isFeatured={isFeatured}
                    setIsFeatured={setIsFeatured}
                />
            )}
        </StFilterContainer>
    );
};

const StFilterContainer = styled.div`
    position: relative;
`;

const StFilter = styled.button<StModalProps>`
    white-space: nowrap;
    background-color: ${(props) => (props.$isOpen ? '#8e00ee' : 'white')};
    color: ${(props) => (props.$isOpen ? 'white' : 'black')};
`;
