import { api } from './api';

export const getOsmWater = async (
    lx: number,
    rx: number,
    ty: number,
    by: number
) => {
    const response = await api.get(
        `/osmJsons/waterGeometries?lx=${lx}&rx=${rx}&ty=${ty}&by=${by}`
    );
    return response.data.value.features;
};
