import { api } from './api';

// export const getPropertiesBySquare = async (lx, rx, ty, by) => {
//     const response = await api.get(
//         `/properties/bysquare/${lx}/${rx}/${ty}/${by}`
//     );
//     return response.data.value.list;
// };

export const getPropertiesWhole = async (
    lx: number,
    rx: number,
    ty: number,
    by: number,
    filterObject?
) => {
    const response = await api.post(
        `/properties/whole/${lx}/${rx}/${ty}/${by}`,
        filterObject
    );
    return response.data.value.list;
};

export const getPropertiesDong = async (
    lx: number,
    rx: number,
    ty: number,
    by: number,
    filterObject?
) => {
    const response = await api.post(
        `/properties/dong/${lx}/${rx}/${ty}/${by}`,
        filterObject
    );
    return response.data.value.list;
};

export const getPropertiesSigungu = async (
    lx: number,
    rx: number,
    ty: number,
    by: number,
    filterObject?
) => {
    const response = await api.post(
        `/properties/sigungu/${lx}/${rx}/${ty}/${by}`,
        filterObject
    );
    return response.data.value.list;
};

export const getPropertiesSido = async (
    lx: number,
    rx: number,
    ty: number,
    by: number,
    filterObject?
) => {
    const response = await api.post(
        `/properties/sido/${lx}/${rx}/${ty}/${by}`,
        filterObject
    );
    return response.data.value.list;
};

export const getProperty = async (propertyId) => {
    const response = await api.get(`/properties/${propertyId}`);
    return response.data.value;
};

export const patchBookmark = async (propertyId) => {
    await api.patch(`/bookmarks/${propertyId}`);
};

export const patchBookmarkMemo = async (propertyId, text) => {
    await api.patch(`/bookmarks/memo/${propertyId}`, { memo: text });
};

export const getMyBookmarks = async () => {
    const response = await api.get(`/bookmarks/my`);
    return response.data.value.list;
};

export const deleteBookmark = async (bookmarkId) => {
    await api.delete(`/bookmarks/byId/${bookmarkId}`);
};

export const getSigunguPropertyList = async (
    sido,
    sigungu,
    lastId,
    filterObject
) => {
    const response = await api.post(
        `/properties/sigungu-list/${sido}/${sigungu}/10/${lastId}`,
        filterObject
    );
    return response.data.value.list;
};

export const getDongPropertyList = async (
    sido,
    sigungu,
    dong,
    lastId,
    filterObject
) => {
    const response = await api.post(
        `/properties/dong-list/${sido}/${sigungu}/${dong}/10/${lastId}`,
        filterObject
    );
    return response.data.value.list;
};
