import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface StPropertyTypeProps {
    $selected: boolean;
}

export const PropertyType = ({
    filterObject,
    setFilterObject,
    isFilteringLoading,
    selectedTypes,
    setSelectedTypes,
}) => {
    const toggleSelection = (type: string) => {
        setSelectedTypes((prevState) => {
            let newState;

            if (type === 'RENT') {
                const areBothSelected =
                    prevState.RENT_MONTHLY && prevState.RENT_CHARTER;
                newState = {
                    ...prevState,
                    RENT_MONTHLY: !areBothSelected,
                    RENT_CHARTER: !areBothSelected,
                };
            } else {
                newState = {
                    ...prevState,
                    [type]: !prevState[type],
                };
            }

            // 필터 객체 업데이트
            const selectedSources = Object.entries(newState)
                .filter(([_, isSelected]) => isSelected)
                .map(([key]) => key);

            setFilterObject({
                ...filterObject,
                sources:
                    selectedSources.length > 0
                        ? selectedSources
                        : ['AUCTION', 'ONBID', 'NAVER'],
            });

            return newState;
        });
    };

    return (
        <StPropertyType>
            <StPropertyTypeList>
                {isFilteringLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <StAuction
                            $selected={selectedTypes.AUCTION}
                            onClick={() => toggleSelection('AUCTION')}
                        >
                            경매
                        </StAuction>
                        <StPublic
                            $selected={selectedTypes.ONBID}
                            onClick={() => toggleSelection('ONBID')}
                        >
                            공매
                        </StPublic>
                        <StTrade
                            $selected={selectedTypes.NAVER}
                            onClick={() => toggleSelection('NAVER')}
                        >
                            매매
                        </StTrade>
                        <StRent
                            $selected={
                                selectedTypes.RENT_MONTHLY &&
                                selectedTypes.RENT_CHARTER
                            }
                            onClick={() => toggleSelection('RENT')}
                        >
                            임대
                        </StRent>
                    </>
                )}
            </StPropertyTypeList>
        </StPropertyType>
    );
};

const StPropertyType = styled.div``;

const StPropertyTypeList = styled.section`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;

const buttonStyle = ($selected) => `
    background-color: ${$selected ? 'initial' : 'white'};
    color: ${$selected ? 'white' : 'black'};
    border: 1px solid ${$selected ? 'transparent' : '#ccc'};
`;

const StAuction = styled.button<StPropertyTypeProps>`
    white-space: nowrap;
    ${(props) => buttonStyle(props.$selected)}
    background-color: ${(props) => (props.$selected ? '#ff427b' : 'white')};
`;

const StPublic = styled.button<StPropertyTypeProps>`
    white-space: nowrap;
    ${(props) => buttonStyle(props.$selected)}
    background-color: ${(props) => (props.$selected ? '#237ffa' : 'white')};
`;

const StTrade = styled.button<StPropertyTypeProps>`
    white-space: nowrap;
    ${(props) => buttonStyle(props.$selected)}
    background-color: ${(props) => (props.$selected ? '#16ae53' : 'white')};
`;

const StRent = styled.button<StPropertyTypeProps>`
    white-space: nowrap;
    ${(props) => buttonStyle(props.$selected)}
    background-color: ${(props) => (props.$selected ? '#ffc800' : 'white')};
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
    border: 5px solid #f3f3f3;
    border-top: 5px solid #8e00ee;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ${spin} 2s linear infinite;
    margin-left: 5px;
`;
