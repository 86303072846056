import React from 'react';
import styled from 'styled-components';
import { zoomIn, zoomOut } from '../controller/ZoomHandlerController';
import { media } from '../../../styles/mediaQueries';

export const ZoomHandler = ({ map }) => (
    <StZoomHandler>
        <StPlus onClick={() => zoomIn(map)}>+</StPlus>
        <StMinus onClick={() => zoomOut(map)}>-</StMinus>
    </StZoomHandler>
);

const StZoomHandler = styled.section`
    display: flex;
    flex-direction: column;
    gap: 3px;

    button {
        background-color: white;
        color: black;
    }

    ${media.tablet`
        display: none;
    `}
`;
const StPlus = styled.button``;
const StMinus = styled.button``;
