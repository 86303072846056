import React, { useState } from 'react';
import styled from 'styled-components';
import { postGeoJsons, putGeoJsons } from '../../../api/geoJsonApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MdOutlineCancel } from 'react-icons/md';

export const RiverModal = ({
    map,
    selectedPolygon,
    setSelectedPolygon,
    setIsOpenRiverModal,
}) => {
    const [range, setRange] = useState('');
    const [waterPolygonName, setWaterPolygonName] = useState('');
    const [currentWaterPolygon, setCurrentWaterPolygon] = useState(null);
    const queryClinet = useQueryClient();

    const putGeoJsonsPayload = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: selectedPolygon.type,
                    coordinates: selectedPolygon.coordinates,
                },
            },
        ],
    };

    const { data: modifiedWaterPolygonData } = useQuery({
        queryKey: [
            'modifiedWaterPolygonData',
            putGeoJsonsPayload,
            range.trim(),
        ],
        queryFn: () => putGeoJsons(putGeoJsonsPayload, range.trim()),
    });

    const postGeoJsonsPayload = {
        type: 'Feature',
        geometry: {
            type: selectedPolygon.type,
            coordinates: modifiedWaterPolygonData?.geometry.coordinates,
        },
        properties: {
            name: waterPolygonName,
            layer: '-1',
            waterway: 'river',
            note: '',
            description: '샘물길 입니다.',
        },
    };

    const postMutation = useMutation({
        mutationFn: postGeoJsons,
        onSuccess: () => {
            queryClinet.invalidateQueries({ queryKey: ['myGeoJsons'] });
            alert('영역 지정이 완료되었습니다.');
            setIsOpenRiverModal(false);
        },
        onError: (error: any) => {
            console.log(error);
            alert('영역 지정에 실패했습니다.');
        },
    });

    const handleCheckArea = async () => {
        if (!selectedPolygon || range.trim() === '') return;

        if (currentWaterPolygon) {
            currentWaterPolygon.setMap(null);
            setCurrentWaterPolygon(null); // 상태 업데이트
        }

        try {
            const newPolygonPath =
                modifiedWaterPolygonData.geometry.coordinates[0].map(
                    (coord) => new naver.maps.LatLng(coord[1], coord[0])
                );
            const newMapPolygon = new naver.maps.Polygon({
                map: map,
                paths: newPolygonPath,
                fillColor: '#8e00ee',
                fillOpacity: 0.1,
                strokeColor: '#8e00ee',
                strokeOpacity: 0.6,
                strokeWeight: 1,
            });

            setCurrentWaterPolygon(newMapPolygon);
        } catch (error) {
            console.error('영역 수정 실패:', error);
            alert('영역 수정에 실패했습니다.');
        }
    };

    const handleCompleteArea = async () => {
        if (!selectedPolygon || waterPolygonName.trim() === '') return;
        postMutation.mutate(postGeoJsonsPayload);
    };

    const cancelModal = () => {
        setIsOpenRiverModal(false);
    };

    return (
        <StRiverModal>
            <StCancelButton onClick={cancelModal}>
                <MdOutlineCancel />
            </StCancelButton>
            <div>강 주변 몇M까지 알림을 받아보시겠어요?</div>
            <StCheckPolygonSection>
                <StDistanceInput
                    value={range}
                    onChange={(e) => setRange(e.target.value)}
                ></StDistanceInput>
                <p>M</p>
                <StCheckButton onClick={handleCheckArea}>
                    영역 확인해보기
                </StCheckButton>
            </StCheckPolygonSection>
            <div>영역의 이름을 입력해주세요</div>
            <StNameInput
                value={waterPolygonName}
                onChange={(e) => setWaterPolygonName(e.target.value)}
            ></StNameInput>
            <StSendButton onClick={handleCompleteArea}>
                영역 지정 완료하기
            </StSendButton>
        </StRiverModal>
    );
};

const StRiverModal = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 270px;
    margin: 0 auto;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 30px 15px 15px 15px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px #919191;

    input {
        height: 20px;
    }

    p {
        font-size: 20px;
    }
`;
const StCancelButton = styled.div`
    color: #8e00ee;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 8px;

    &:hover {
        opacity: 0.6;
    }
`;

const StCheckPolygonSection = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;
const StDistanceInput = styled.input`
    border-radius: 3px;
    border: 0.5px solid #5f5f5f;
    width: 100px;
`;
const StCheckButton = styled.div`
    background-color: #8e00ee;
    color: white;
    font-size: 10px;
    padding: 7px;
    border-radius: 12px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;
const StNameInput = styled.input`
    border-radius: 3px;
    width: 250px;
    border: 0.5px solid #5f5f5f;
`;
const StSendButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background-color: #8e00ee;
    color: white;
    font-size: 14px;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;
