import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { media } from '../../../styles/mediaQueries';

interface FilterObjcet {
    minPrice?: number;
    maxPrice?: number;
    usageTypes?: string[];
    name?: string;
    address?: string;
    sources?: string[];
}

export const FilterModal = ({
    filterObject,
    setFilterObject,
    selectedTags,
    setSelectedTags,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    isRiverSide,
    setIsRiverSide,
    isFilteringLoading,
    isFeatured,
    setIsFeatured,
}) => {
    // 태그 데이터 정의
    const tags = {
        주거: [
            '아파트',
            '오피스텔',
            '다가구주택',
            '단독주택',
            '연립주택',
            '주상복합',
            '원룸',
            '기타',
        ],
        '상업 및 업무': ['상가', '사무실', '기타'],
        '산업 및 특수': ['공장', '창고', '기타'],
        토지: ['대지', '임야', '잡종지', '전답', '기타'],
    };
    const [keepMinPrice, setKeepMinPrice] = useState(minPrice);
    const [keepMaxPrice, setKeepMaxPrice] = useState(maxPrice);
    const [isFeatured2, setIsFeatured2] = useState(false);

    const toggleIsRiverSide = () => {
        const newIsRiverSide = !isRiverSide;
        setIsRiverSide(newIsRiverSide); // 강변 매물만 보기 상태 토글
        setFilterObject({
            ...filterObject,
            isRiverSide: newIsRiverSide, // filterObject 업데이트
        });
    };

    const toggleIsFeatured = () => {
        const newIsFeatured = !isFeatured;
        setIsFeatured(newIsFeatured); // 추천 매물만 보기 상태 토글
        setFilterObject({
            ...filterObject,
            isFeatured: newIsFeatured, // filterObject 업데이트
        });
    };

    const toggleTag = (category, tag) => {
        const tagTuple = [category, tag];
        const currentIndex = selectedTags.findIndex(
            ([cat, t]) => cat === category && t === tag
        );
        const newSelectedTags = [...selectedTags];

        if (currentIndex === -1) {
            newSelectedTags.push(tagTuple);
        } else {
            newSelectedTags.splice(currentIndex, 1);
        }

        setSelectedTags(newSelectedTags);
        setFilterObject({ ...filterObject, usageTypes: newSelectedTags });
    };

    const resetFilters = () => {
        setKeepMinPrice('');
        setKeepMaxPrice('');
        setSelectedTags([]);
        setIsFeatured(false);
        setFilterObject({
            ...filterObject,
            usageTypes: [],
            minPrice: undefined,
            maxPrice: undefined,
            isRiverSide: undefined,
            isFeatured: undefined,
        });
    };

    const handleMinPriceChange = (e) => {
        setKeepMinPrice(e.target.value); // 상태 업데이트
    };

    const handleMaxPriceChange = (e) => {
        setKeepMaxPrice(e.target.value); // 상태 업데이트
    };

    const handleSearch = () => {
        const actualMinPrice = keepMinPrice
            ? Number(keepMinPrice) * 100000000
            : undefined;
        const actualMaxPrice = keepMaxPrice
            ? Number(keepMaxPrice) * 100000000
            : undefined;
        setFilterObject({
            ...filterObject,
            minPrice: actualMinPrice,
            maxPrice: actualMaxPrice,
        });
    };

    return (
        <StFilterModal>
            {isFilteringLoading ? (
                <Spinner />
            ) : (
                <>
                    <StReset onClick={resetFilters}>초기화</StReset>
                    {/* <StOnlyRiver
                        onClick={toggleIsRiverSide}
                        selected={isRiverSide}
                    >
                        강변 매물만 보기
                    </StOnlyRiver> */}
                    <></>
                    <StOnlyFeatured
                        onClick={toggleIsFeatured}
                        selected={isFeatured}
                    >
                        추천 매물만 보기
                    </StOnlyFeatured>
                    <StOnlyFeatured
                        onClick={()=>{
                            setIsFeatured2(!isFeatured2);
                            if(isFeatured2===false){
                                setFilterObject({
                                    ...filterObject,
                                    name: "한옥", // filterObject 업데이트
                                });
                            }else{
                                setFilterObject({
                                    ...filterObject,
                                    name: "", // filterObject 업데이트
                                });
                            }
                        }}
                        selected={isFeatured2}
                    >
                        한옥 매물만 보기
                    </StOnlyFeatured>
                    {Object.entries(tags).map(([category, tags]) => (
                        <StContainer key={category}>
                            <h3>{category}</h3>
                            <StTagList>
                                {tags.map((tag) => (
                                    <StTag
                                        key={tag}
                                        selected={selectedTags.some(
                                            ([cat, t]) =>
                                                cat === category && t === tag
                                        )}
                                        onClick={() => toggleTag(category, tag)}
                                    >
                                        {tag}
                                    </StTag>
                                ))}
                            </StTagList>
                        </StContainer>
                    ))}
                    <StPriceContainer>
                        <h3>가격</h3>
                        <StInputContainer>
                            <StInput
                                value={keepMinPrice}
                                onChange={handleMinPriceChange}
                            />
                            <StMinText>억 이상</StMinText>
                            <StInput
                                value={keepMaxPrice}
                                onChange={handleMaxPriceChange}
                            />
                            <StMaxText>억 미만으로</StMaxText>
                            <StSearch onClick={handleSearch}>검색</StSearch>
                        </StInputContainer>
                    </StPriceContainer>
                </>
            )}
        </StFilterModal>
    );
};

const StFilterModal = styled.div`
    position: absolute;
    top: -140px;
    right: 80px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    padding: 15px;
    z-index: 90;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #919191;
    width: 350px;
    max-height: 430px;
    overflow: auto;

    h3 {
        margin-left: 3px;
        font-weight: bold;
    }

    ${media.tablet`
        top: -80px;
        width: 250px;
        max-height: 300px;
        gap: 8px;
        right: 50px;

        h3 {
            font-size: 12px;
        }
    `}
`;

const StContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.tablet`        
        gap: 4px;
    `}
`;

const StTagList = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    ${media.tablet`        
        gap: 5px;
    `}
`;

const StOnlyRiver = styled.div<{ selected?: boolean }>`
    display: flex;
    justify-content: center;
    width: 100px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #8e00ee;
    background-color: ${(props) => (props.selected ? '#8e00ee' : 'white')};
    color: ${(props) => (props.selected ? 'white' : '#8e00ee')};

    &:hover {
        opacity: 0.7;
    }

    ${media.tablet`  
        width: 70px;      
        font-size: 10px;
        padding: 7px;
    `}
`;

const StOnlyFeatured = styled.div<{ selected?: boolean }>`
    display: flex;
    justify-content: center;
    width: 100px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #8e00ee;
    background-color: ${(props) => (props.selected ? '#8e00ee' : 'white')};
    color: ${(props) => (props.selected ? 'white' : '#8e00ee')};

    &:hover {
        opacity: 0.7;
    }

    ${media.tablet`  
        width: 70px;      
        font-size: 10px;
        padding: 7px;
    `}
`;

const StTag = styled.div<{ selected?: boolean }>`
    background-color: ${(props) => (props.selected ? '#8e00ee' : 'none')};
    color: ${(props) => (props.selected ? 'white' : '#8e00ee')};
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    padding: 10px;
    border-radius: 50px;
    border: 1px solid #8e00ee;

    &:hover {
        opacity: 0.7;
    }

    ${media.tablet`        
        font-size: 10px;
        padding: 7px;
    `}
`;

const StPriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    ${media.tablet`        
        gap: 5px;
    `}
`;

const StInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #8e00ee;

    input {
        border: 1px solid #8e00ee;
        width: 40px;
        border-radius: 5px;
    }

    ${media.tablet`        
        gap: 5px;

        input {      
        width: 30px;
        border-radius: 5px;
    }
    `}
`;
const StInput = styled.input``;
const StMinText = styled.div`
    font-weight: bold;
    font-size: 14px;
    margin-right: 10px;

    ${media.tablet`        
    font-size: 10px;
    margin-right: 6px;
    `}
`;
const StMaxText = styled.div`
    font-weight: bold;
    font-size: 14px;

    ${media.tablet`        
    font-size: 10px;
    `}
`;

const StReset = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #8e00ee;
    font-size: 14px;

    &:hover {
        opacity: 0.7;
    }
    ${media.tablet`        
    font-size: 10px;
    `}
`;

const StSearch = styled.div`
    background-color: #8e00ee;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    margin-left: 5px;

    &:hover {
        opacity: 0.7;
    }

    ${media.tablet`        
    padding: 4px 8px;
    font-size: 12px;
    `}
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스피너 스타일링
const Spinner = styled.div`
    border: 10px solid #f3f3f3;
    border-top: 10px solid #8e00ee;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite;

    margin: 30px 130px;

    ${media.tablet`        
    margin: 30px 90px;
    `}
`;
