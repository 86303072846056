import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { deleteGeoJson, getGeoJsonCenter } from '../../../api/geoJsonApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { media } from '../../../styles/mediaQueries';

export const PolygonListModal = ({ map, myGeoJsons }) => {
    const queryClient = useQueryClient();

    const deletePolygonMutation = useMutation({
        mutationFn: (myGeoJsonId) => deleteGeoJson(myGeoJsonId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['myGeoJsons'] });
            window.location.reload();
        },
    });

    const handleDelete = async (e, myGeoJsonId) => {
        e.stopPropagation();
        const isConfirmed = window.confirm('정말로 삭제하시겠습니까?');
        if (isConfirmed) {
            deletePolygonMutation.mutate(myGeoJsonId);
        }
    };

    console.log(myGeoJsons);

    const moveToPolygon = async (id) => {
        const response = await getGeoJsonCenter(id);
        const [lng, lat] = response.geometry.coordinates;

        if (map) {
            map.setCenter(new naver.maps.LatLng(lat, lng));
            map.setZoom(16);
        }
    };

    return (
        <StPolygonListModal>
            <h2>내가 지정한 영역</h2>
            <StPolygonList>
                {myGeoJsons.length > 0 ? (
                    myGeoJsons.map((myGeoJson) => (
                        <StPolygon
                            key={myGeoJson._id}
                            onClick={() => moveToPolygon(myGeoJson._id)}
                        >
                            <p>{myGeoJson.properties.name}</p>
                            <div
                                onClick={(e) => handleDelete(e, myGeoJson._id)}
                            >
                                x
                            </div>
                        </StPolygon>
                    ))
                ) : (
                    <StEmpty>리스트가 비어있습니다.</StEmpty>
                )}
            </StPolygonList>
        </StPolygonListModal>
    );
};

const StPolygonListModal = styled.div`
    position: absolute;
    top: 0;
    right: 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 30px 15px;
    z-index: 90;
    border-radius: 2px;
    box-shadow: 1px 1px 1px #919191;
    width: 250px;
    max-height: 250px;
    overflow: auto;

    h2 {
        display: block;
        font-weight: bold;
        font-size: 18px;
    }

    ${media.tablet`
        right: 50px;
        top: -40px;
        max-height: 120px;
    `}
`;

const StPolygonList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const StPolygon = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    padding: 10px 18px 10px 10px;
    background-color: #8e00ee;
    font-size: 14px;
    color: white;
    border-radius: 4px;
    white-space: nowrap;
    min-width: 20px;

    &:hover {
        opacity: 0.8;
    }

    div {
        position: absolute;
        top: 3px;
        right: 5px;

        &:hover {
            font-size: 17px;
        }
    }
`;

const StEmpty = styled.div`
    margin-left: 20px;
    margin-top: 20px;
    color: grey;
    font-size: 14px;
`;
