import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaRegStar } from 'react-icons/fa6';
import { FaStar } from 'react-icons/fa';
import {
    getProperty,
    patchBookmark,
    patchBookmarkMemo,
} from '../../../api/propertyApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { media } from '../../../styles/mediaQueries';
import { PatchBookmarkMemoArgs } from './BookmarkContainer';
import { MdOutlineCancel } from 'react-icons/md';

export const Detail = ({ propertyId, setIsDetailModalOpen }) => {
    const queryClient = useQueryClient();
    let text = '';

    const {
        data: property,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['property', propertyId],
        queryFn: () => getProperty(propertyId),
    });

    console.log('detail', property);

    const bookmarkMutation = useMutation({
        mutationFn: () => patchBookmark(propertyId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['property', propertyId],
            });
            queryClient.invalidateQueries({
                queryKey: ['myBookmarks'],
            });

            if (!property.isBookmarked) {
                text = prompt('메모를 입력하세요');
                pathchBookmarkMemoMutation.mutate();
            }
        },
    });

    const toggleBookmark = () => {
        bookmarkMutation.mutate();
    };

    const pathchBookmarkMemoMutation = useMutation({
        mutationFn: () => patchBookmarkMemo(propertyId, text),
        onSuccess: () => {
            // 메모 저장 성공 후 필요한 쿼리 무효화 및 재요청
            queryClient.invalidateQueries({
                queryKey: ['property', propertyId, text],
            });
            queryClient.invalidateQueries({ queryKey: ['myBookmarks'] });
        },
    });

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert('클립보드에 복사됐어요!');
        } catch (err) {
            console.error('클립보드에 복사하는 중 오류가 발생했습니다:', err);
        }
    };

    const handleClipBoardClick = () => {
        if (property.source === 'ONBID') {
            copyToClipboard(property.name);
        } else if (property.source === 'AUCTION') {
            const lastTagyeongIndex = property.name.lastIndexOf('타경');
            if (lastTagyeongIndex !== -1) {
                const tagyeongSubstring = property.name.substring(
                    lastTagyeongIndex + 2
                );
                const numericPart = tagyeongSubstring.match(/\d+/);
                if (numericPart) {
                    copyToClipboard(numericPart[0]);
                }
            }
        }
    };

    const editMemoMutation = useMutation({
        mutationFn: ({ propertyId, newMemo }: PatchBookmarkMemoArgs) =>
            patchBookmarkMemo(propertyId, newMemo),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['property'] });
        },
    });

    const handleMemoEdit = async (propertyId: any, oldMemo: any) => {
        const newMemo = prompt('새 메모를 입력하세요:', oldMemo);
        if (newMemo !== null) {
            editMemoMutation.mutate({ propertyId, newMemo });
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <StDetail>
            <StCancelButton
                onClick={() => {
                    setIsDetailModalOpen(false);
                }}
            >
                <MdOutlineCancel />
            </StCancelButton>
            <StBookmark onClick={toggleBookmark}>
                {property.isBookmarked ? <FaStar /> : <FaRegStar />}
            </StBookmark>
            {property.isBookmarked && (
                <StMemoContainer>
                    <StMemo>"{property.memo}"</StMemo>
                    <StModifyContainer>
                        <StModify
                            onClick={() =>
                                handleMemoEdit(property._id, property.memo)
                            }
                        >
                            메모 수정
                        </StModify>
                    </StModifyContainer>
                </StMemoContainer>
            )}
            <StTagList>
                <StTag>
                    {property.source === 'NAVER' && '매매'}
                    {property.source === 'AUCTION' && '경매'}
                    {property.source === 'ONBID' && '공매'}
                    {property.source === 'RENT_MONTHLY' && '월세'}
                    {property.source === 'RENT_CHARTER' && '전세'}
                </StTag>
                <StTag>{property.category}</StTag>
                {property.isFeatured && <StTag>추천매물</StTag>}
            </StTagList>
            <StNaverMapLink>
                <a
                    href={`https://map.naver.com/p/search/${property.geoJson.properties.address},jibun?c=18.00,0,0,0,dh&isCorrectAnswer=true`}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    네이버 지도에서 보기
                </a>
            </StNaverMapLink>
            {(property.source === 'NAVER' ||
                property.source === 'RENT_MONTHLY' ||
                property.source === 'RENT_CHARTER') && (
                <StLinkList>
                    <StNaverLink
                        href={`https://new.land.naver.com/houses?articleNo=${
                            property.name.split(' ')[1]
                        }`}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        네이버 매물 🔗
                    </StNaverLink>
                    <StAgentLink
                        href={property.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        부동산 🔗
                    </StAgentLink>
                </StLinkList>
            )}
            {property.source === 'AUCTION' && (
                <StLinkList>
                    <StAuctionLink
                        href={property.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        법원경매 🔗
                    </StAuctionLink>
                    <StAuctionOneContainer>
                        <StAuctionClipBoard onClick={handleClipBoardClick}>
                            타경번호 복사 💾
                        </StAuctionClipBoard>
                        <StAuctionLink
                            href={`https://www.auction1.co.kr/auction/ca_title.php`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            옥션원 🔗
                        </StAuctionLink>
                    </StAuctionOneContainer>
                </StLinkList>
            )}
            {property.source === 'ONBID' && (
                <StLinkList>
                    <StOnbidLink
                        href={property.link}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        온비드 🔗
                    </StOnbidLink>
                </StLinkList>
            )}
            <StAddress>{property.geoJson.properties.address}</StAddress>
            <StContainer>
                <p>매물번호</p>
                <StNumber>{property.name}</StNumber>
            </StContainer>
            {(property.source === 'NAVER' ||
                property.source === 'RENT_MONTHLY' ||
                property.source === 'RENT_CHARTER') && (
                <>
                    <StContainer>
                        <p>가격</p>
                        <StDealOrWarrentPrc>
                            {new Intl.NumberFormat('ko-KR').format(
                                Number(property.price)
                            )}
                        </StDealOrWarrentPrc>
                    </StContainer>
                    <StContainer>
                        <p>대지/연면적</p>
                        <StArea>{property.area}</StArea>
                    </StContainer>
                    <StContainer>
                        <p>업체명</p>
                        <StCpName>{property.cpName}</StCpName>
                    </StContainer>
                    <StContainer>
                        <p>중개사명</p>
                        <StRealtorName>{property.realtorName}</StRealtorName>
                    </StContainer>
                    {property.floorInfo && (
                        <StContainer>
                            <p>층</p>
                            <StFloorInfo>{property.floorInfo}</StFloorInfo>
                        </StContainer>
                    )}
                    {property.direction && (
                        <StContainer>
                            <p>향</p>
                            <StDirection>{property.direction}</StDirection>
                        </StContainer>
                    )}
                </>
            )}
            {property.source === 'AUCTION' && (
                <>
                    <StContainer>
                        <p>감정가</p>
                        <StAppraisalValue>
                            {new Intl.NumberFormat('ko-KR').format(
                                Number(property.appraisalValue)
                            )}
                        </StAppraisalValue>
                    </StContainer>
                    <StContainer>
                        <p>최소입찰가</p>
                        <StMinimumAuctionPrice>
                            {new Intl.NumberFormat('ko-KR').format(
                                Number(property.price)
                            )}
                        </StMinimumAuctionPrice>
                    </StContainer>
                    <StContainer>
                        <p>경매진행상태</p>
                        <StAuctionStatus>
                            {property.auctionStatus}
                        </StAuctionStatus>
                    </StContainer>
                </>
            )}
            {property.source === 'ONBID' && (
                <>
                    <StContainer>
                        <p>입찰방식</p>
                        <StBidMtdNm>{property.bidMtdNm}</StBidMtdNm>
                    </StContainer>
                    <StContainer>
                        <p>감정가</p>
                        <StApslAsesAvgAmt>
                            {new Intl.NumberFormat('ko-KR').format(
                                Number(property.apslAsesAvgAmt)
                            )}
                        </StApslAsesAvgAmt>
                    </StContainer>
                    <StContainer>
                        <p>최저입찰가</p>
                        <StMinBidPrc>
                            {new Intl.NumberFormat('ko-KR').format(
                                Number(property.price)
                            )}
                        </StMinBidPrc>
                    </StContainer>
                    <StContainer>
                        <p>최저입찰가율</p>
                        <StFeeRate>{property.feeRate}</StFeeRate>
                    </StContainer>
                    <StContainer>
                        <p>입찰시작일시</p>
                        <StPbctBegnDtm>{property.pbctBegnDtm}</StPbctBegnDtm>
                    </StContainer>
                    <StContainer>
                        <p>입찰마감일시</p>
                        <StPbctClsDtm>{property.pbctClsDtm}</StPbctClsDtm>
                    </StContainer>
                    <StContainer>
                        <p>물건상태</p>
                        <StPbctCltrStatNm>
                            {property.pbctCltrStatNm}
                        </StPbctCltrStatNm>
                    </StContainer>
                    <StContainer>
                        <p>유찰횟수</p>
                        <StUscbdCnt>{property.uscbdCnt}</StUscbdCnt>
                    </StContainer>
                    <StContainer>
                        <p>물건상세정보</p>
                        <StGoodNm>{property.goodNm}</StGoodNm>
                    </StContainer>
                </>
            )}
        </StDetail>
    );
};

const StDetail = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    position: absolute;
    top: 100px;
    left: 0;
    width: 240px;
    max-width: 240px;
    max-height: 650px;
    overflow-y: auto;
    box-shadow: 1px 1px 1px #919191;
    padding: 30px 15px;
    border-radius: 2px;

    ${media.tablet`
    top: 50px;
    max-height: 520px;
    `}
`;

const StCancelButton = styled.div`
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 15px;
    right: 18px;
    color: #8e00ee;

    &:hover {
        opacity: 0.5;
    }
`;

const StBookmark = styled.div`
    width: 30px;
    color: #8e00ee;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const StTagList = styled.div`
    display: flex;
    gap: 10px;
`;

const StNaverMapLink = styled.div`
    color: #16ae53;
`;

const StTag = styled.div`
    background-color: #8e00ee;
    color: white;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 12px;
    border-radius: 2px;
`;
const StLinkList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
`;

const StAuctionOneContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const StNaverLink = styled.a`
    color: #16ae53;

    &:hover {
        opacity: 0.7;
    }
`;
const StAuctionLink = styled.a`
    color: #ff427b;

    &:hover {
        opacity: 0.7;
    }
`;
const StOnbidLink = styled.a`
    color: #237ffa;

    &:hover {
        opacity: 0.7;
    }
`;
const StAuctionClipBoard = styled.div`
    color: #ff427b;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const StOnbidClipBoard = styled.div`
    color: #237ffa;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;
const StAgentLink = styled.a`
    color: #b548ff;

    &:hover {
        opacity: 0.7;
    }
`;
const StContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;

    p {
        font-size: 14px;
        font-weight: bold;
    }
`;
const StAddress = styled.div`
    font-weight: bold;
`;
const StNumber = styled.div``;
const StAppraisalValue = styled.div``;
const StMinimumAuctionPrice = styled.div``;
const StAuctionStatus = styled.div``;
const StDealOrWarrentPrc = styled.div``;
const StArea = styled.div``;
const StCpName = styled.div``;
const StRealtorName = styled.div``;
const StFloorInfo = styled.div``;
const StDirection = styled.div``;
const StBidMtdNm = styled.div``;
const StApslAsesAvgAmt = styled.div``;
const StMinBidPrc = styled.div``;
const StFeeRate = styled.div``;
const StPbctBegnDtm = styled.div``;
const StPbctClsDtm = styled.div``;
const StPbctCltrStatNm = styled.div``;
const StUscbdCnt = styled.div``;
const StGoodNm = styled.div``;

const StMemoContainer = styled.div`
    display: flex;
    gap: 10px;
    word-break: break-all;
    max-width: 230px;
    align-items: center;
`;

const StMemo = styled.p`
    font-size: 12px;
    font-weight: bold;
`;

const StModifyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
`;

const StModify = styled.div`
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #6c6c6c;
    color: #6c6c6c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 2px 5px;
    white-space: nowrap;

    &:hover {
        opacity: 0.7;
    }
`;

const StDelete = styled.div`
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #ff4a68;
    color: #ff4a68;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 2px 5px;

    &:hover {
        opacity: 0.7;
    }
`;
