const zoomIn = (map) => {
    if (map) {
        const currentZoom = map.getZoom();
        map.setZoom(currentZoom + 1);
    }
};

const zoomOut = (map) => {
    if (map) {
        const currentZoom = map.getZoom();
        map.setZoom(currentZoom - 1);
    }
};

export { zoomIn, zoomOut };
