import { api } from './api';

export const postGeoJsons = async (data: any) => {
    await api.post(`/geoJsons`, data);
};

export const putGeoJsons = async (data: any, range: any) => {
    const response = await api.put(`/geoJsons/buffer/${range}`, data);
    return response.data.value;
};

export const getMyGeoJsons = async () => {
    const response = await api.get(`/geoJsons/my`);
    return response.data.value.list;
};

export const deleteGeoJson = async (id) => {
    await api.delete(`/geoJsons/byId/${id}`);
};

export const getGeoJsonCenter = async (id) => {
    const response = await api.get(`/geoJsons/centroid/${id}`);
    return response.data.value;
};

export const getMyGeoJsonItems = async (data) => {
    const response = await api.post(`/properties/geoWithin`, data);
    return response.data.value.list;
};
