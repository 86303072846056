import { api } from './api';

export const getSummary = async () => {
    const response = await api.get(`/statistics`);
    return response.data.value;
};

export const getItemsInMyPolygons = async () => {
    const response = await api.get(`/properties/geowithin/polygons`);
    return response.data.value.list;
};
